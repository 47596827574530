import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';

import { IReportFilterValue, IReportTableFilters } from '../../../types/report';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import Scrollbar from '../../../components/scrollbar';
import { getActiveMemberReport } from '../../../redux/slices/report';
import ActiveMemberTableRow from './active-member-table-row';
import { useRouter } from '../../../routes/hook';
import { convertToCSV } from '../../../utils/array-utils';
import ActiveMemberReportToolbar from './active-member-report-toolbar';
import { fCurrency, fNumber, fShortenNumber } from '../../../utils/format-number';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

// ----------------------------------------------------------------------

export default function ActiveMemberReportView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const router = useRouter();

  const tableHeadMembers = [
    { id: 'name', label: t('Member'), align: 'left' },
    { id: 'email', label: t('Email'), align: 'left' },
    { id: 'phoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'membershipSum', label: t('Membership sum'), align: 'left' },
    { id: 'numberOfMemberships', label: t('Number of memberships'), align: 'left' },
    { id: '' },
  ];

  const tableMembers = useTable({
    defaultOrderBy: tableHeadMembers[0].id,
  });

  const report = useSelector((state) => state.report.activeMemberReport);
  const tableData = report.members ?? [];
  const newNotFound = !tableData.length;

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(getActiveMemberReport(filters));
  }, [dispatch, filters]);

  const handleFilters = useCallback(
    (name: string, value: IReportFilterValue) => {
      tableMembers.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [tableMembers]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const exportCsv = useCallback(() => {
    const newMembers = convertToCSV(
      (report.members ?? []).map((el) => ({
        id: el.id,
        name: el.name ?? '',
        email: el.email ?? '',
        phoneNumber: el.phoneNumber ?? '',
        numberOfMemberships: el.numberOfMemberships ?? 0,
        sumOfMemberships: el.sumOfMemberships ?? '',
        currency: el.currency ?? '',
      }))
    );

    // Ignore type, because API can return multiple types
    const newBlob = new Blob([newMembers as any], {
      type: 'text/csv',
    });
    saveAs(newBlob, `report.csv`);
  }, [report]);

  const handlePrintPage: VoidFunction = () => window.print();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Active members report')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Reports')}`,
            href: paths.report.root,
          },
          { name: `${t('Active members report')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} id="printarea">
        <Card>
          <ActiveMemberReportToolbar
            filters={filters}
            onFilters={handleFilters}
            onPrint={handlePrintPage}
            onExport={exportCsv}
          />
        </Card>
        <Card>
          <CardHeader title={t('Overview')} />
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <th align="left">{t('Average number of memberships')}</th>
                  <td align="right">
                    {fShortenNumber(report.averageNumberOfMembershipsPerMember ?? 0)}
                  </td>
                </tr>
                <tr>
                  <th align="left">{t('Average sum of memberships')}</th>
                  <td align="right">
                    {fCurrency(report.averageSumOfMembershipsPerMember, report.currency)}
                  </td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title={t('Members')} />
          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              pt: 2.5,
            }}
          >
            <Scrollbar>
              <Table size={tableMembers.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={tableMembers.order}
                  orderBy={tableMembers.orderBy}
                  headLabel={tableHeadMembers}
                  rowCount={tableData.length}
                  numSelected={tableMembers.selected.length}
                  onSort={tableMembers.onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <ActiveMemberTableRow
                      key={row.id}
                      row={row}
                      onViewRow={() => handleViewRow(row.id!)}
                      onSelectRow={() => handleViewRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    emptyRows={emptyRows(
                      tableMembers.page,
                      tableMembers.rowsPerPage,
                      tableData.length
                    )}
                  />

                  <TableNoData notFound={newNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tableData.length}
            page={tableMembers.page}
            rowsPerPage={25}
            onPageChange={tableMembers.onChangePage}
            onRowsPerPageChange={tableMembers.onChangeRowsPerPage}
          />
        </Card>
      </Stack>
    </Container>
  );
}
