import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { enqueueSnackbar } from 'notistack';
//
import ProfileHome from '../profile-home';
import ProfileCover from '../profile-cover';
import ProfileTraining from '../profile-training';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { useParams, useRouter } from '../../../routes/hook';
import {
  getCurrentMemberStats,
  getMember,
  getMemberBalance,
  getMemberComments,
  getMemberEvents,
  getMemberExerciseResults,
  getMemberInvoices,
  getMemberPaymentMethods,
  getMemberships,
} from '../../../redux/slices/members';
import AccountBilling from '../account-billing';
import { getGyms } from '../../../redux/slices/gym';
import PersonalDocument from '../personal-document';
import {
  AccessSystemProviders,
  Direction,
  EmployeesService,
  MembershipStatuses,
  MembersService,
  SubscriptionType,
} from '../../../api';
import ProfileHealth from '../profile-health';
import { getChain, getChainSettings } from '../../../redux/slices/chain';
import { RouterLink } from '../../../routes/components';
import SubAccountList from '../sub-accounts/sub-account-list';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { ConfirmDialog } from '../../../components/custom-dialog';
import { useBoolean } from '../../../hooks/use-boolean';
import { setError } from '../../../redux/slices/error';

// ----------------------------------------------------------------------

export default function UserProfileView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const router = useRouter();

  const popover = usePopover();

  const confirm = useBoolean();

  const TABS = [
    {
      value: 'profile',
      label: t('Profile'),
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
    {
      value: 'billing',
      label: t('Billing'),
      icon: <Iconify icon="solar:bill-list-bold" width={24} />,
    },
    {
      value: 'training',
      label: t('Training'),
      icon: <Iconify icon="solar:dumbbell-large-bold-duotone" width={24} />,
    },
    {
      value: 'accounts',
      label: t('Accounts'),
      icon: <Iconify icon="raphael:parent" width={24} />,
    },
    {
      value: 'health',
      label: t('Health'),
      icon: <Iconify icon="solar:health-bold-duotone" width={24} />,
    },
    {
      value: 'document',
      label: t('Documents'),
      icon: <Iconify icon="solar:folder-2-bold-duotone" width={24} />,
    },
  ];

  const params = useParams();

  const dispatch = useDispatch();

  const { id } = params;

  const chain = useSelector((state) => state.chain.currentChain);

  const chainSettings = useSelector((state) => state.chain.currentSettings);

  const member = useSelector((state) => state.member.currentMember);

  const memberships = useSelector((state) => state.member.currentMemberMemberships);

  const exerciseResults = useSelector((state) => state.member.currentMemberExerciseResults);

  const memberStats = useSelector((state) => state.member.currentMemberStats);

  const events = useSelector((state) => state.member.currentMemberEvents);

  const comments = useSelector((state) => state.member.currentMemberComments);

  const invoices = useSelector((state) => state.member.currentMemberInvoices);

  const paymentMethods = useSelector((state) => state.member.currentMemberPaymentMethods);

  const memberBalance = useSelector((state) => state.member.currentMemberBalance);

  const gyms = useSelector((state) => state.gym.gyms);

  const coverImages = gyms.flatMap((e) => e.images ?? []);

  const defaultValues = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedCurrentTab = queryParams.get('currentTab');

    return {
      storedCurrentTab: storedCurrentTab ?? 'profile',
    };
  }, []);

  const [currentTab, setCurrentTab] = useState(defaultValues.storedCurrentTab);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?currentTab=${currentTab}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [currentTab]);

  const selectedGym = useSelector((state) => state.gym.globalGym);

  const handleResetDoorAccess = useCallback(() => {
    const request = async () => {
      await MembersService.updateDoorAccess({
        memberId: member.id!,
      });
      dispatch(getMember(member.id!));

      enqueueSnackbar(t('Door access reset!'));
    };

    request();
  }, [member, t, dispatch]);

  const handleResetPassword = useCallback(() => {
    const request = async () => {
      const result = await MembersService.updatePassword({
        id: member.id!,
      });
      enqueueSnackbar(
        t('Password has been reset and sent to member! New password is {{password}}', {
          password: result.value ?? '',
        })
      );
    };

    request();
  }, [member, t]);

  const handleTurnIntoEmployee = useCallback(() => {
    const request = async () => {
      try {
        const result = await EmployeesService.create({
          body: {
            email: member.email,
            firstName: member.firstName,
            lastName: member.lastName,
            middleName: member.middleName,
            phoneNumber: member.phoneNumber,
            gender: member.gender,
            roles: ['Coach', 'Member'],
            dateOfBirth: member.dateOfBirth,
            addressLine: member.address?.addressLine,
            city: member.address?.city,
            postalNumber: member.address?.postalNumber,
            country: member.address?.country,
            gymId: selectedGym?.id,
          },
        });

        router.push(paths.employee.details(result.id!));

        enqueueSnackbar(t('Door access reset!'));
      } catch (err) {
        dispatch(setError(err));
      }
    };

    request();
  }, [member, t, router, selectedGym, dispatch]);

  const handleLinkToExternal = useCallback(() => {
    window.open(member.externalLink);
  }, [member]);

  useEffect(() => {
    const memberId = parseInt(id!, 10);
    dispatch(getMember(memberId));
    dispatch(getMemberEvents(memberId));
    dispatch(getMemberInvoices(memberId));
    dispatch(getMemberBalance(memberId));
    dispatch(getMemberPaymentMethods(memberId));
    dispatch(getGyms());
    dispatch(getChain());
    dispatch(getChainSettings());
    dispatch(getCurrentMemberStats(memberId));
    dispatch(
      getMemberships({
        id: memberId,
        statuses: [
          MembershipStatuses.Active,
          MembershipStatuses.InActive,
          MembershipStatuses.OnHold,
          MembershipStatuses.Cancelled,
          MembershipStatuses.MissingPayment,
        ],
      })
    );
    dispatch(getMemberComments(memberId));
    dispatch(
      getMemberExerciseResults(memberId, {
        pageSize: 10,
        sortBy: ['createdDate'],
        direction: Direction.Desc,
      })
    );
  }, [dispatch, id]);

  const coverUrl = coverImages[Math.floor(Math.random() * coverImages.length)]?.url ?? '';

  const hasDoorAccessSystem = chainSettings.accessSystemProvider !== undefined;

  const doorAccessStatus = (() => {
    if (chainSettings.accessSystemProvider === AccessSystemProviders.Danalock) {
      return 'Yes';
    }

    if (member.hasDoorSystemUser) {
      return 'Yes';
    }
    const hasActiveMembership =
      memberships.filter((x) =>
        [MembershipStatuses.Active, MembershipStatuses.MissingPayment].includes(x.status!)
      ).length > 0;
    if (!hasActiveMembership) {
      return 'LockedOut';
    }
    return 'No';
  })();

  const handleAddNewMembership = useCallback(() => {
    dispatch(
      getMemberships({
        id: member.id!,
        statuses: [
          MembershipStatuses.Active,
          MembershipStatuses.InActive,
          MembershipStatuses.OnHold,
          MembershipStatuses.Cancelled,
          MembershipStatuses.MissingPayment,
        ],
      })
    );
    dispatch(getMemberBalance(member.id!));
    dispatch(getMemberInvoices(member.id!));
  }, [member, dispatch]);

  const handleDelete = useCallback(() => {
    confirm.onFalse();
    const action = async () => {
      await MembersService.delete({
        id: member.id!,
      });
      router.back();
    };
    action();
  }, [member, confirm, router]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Member Profile')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            { name: t('Member'), href: paths.members.root },
            { name: `${member?.fullName} (${member?.id})` },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          action={
            <>
              {hasDoorAccessSystem && doorAccessStatus === 'Yes' && (
                <>
                  <Tooltip title={t('Member has access to the door system')}>
                    <IconButton
                      sx={{
                        mr: 1,
                      }}
                      color="success"
                      onClick={handleResetDoorAccess}
                    >
                      <Iconify icon="solar:lock-keyhole-unlocked-bold-duotone" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {hasDoorAccessSystem && doorAccessStatus === 'No' && (
                <>
                  <Tooltip
                    title={t(
                      'A broken lock indicates a issue with the sync. between Booking Board and the door access system'
                    )}
                  >
                    <IconButton
                      sx={{
                        mr: 1,
                      }}
                      color="warning"
                      onClick={handleResetDoorAccess}
                    >
                      <Iconify icon="solar:lock-keyhole-broken" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {hasDoorAccessSystem && doorAccessStatus === 'LockedOut' && (
                <>
                  <Tooltip title={t('Member is locked out of door access system')}>
                    <IconButton
                      sx={{
                        mr: 1,
                      }}
                      color="error"
                      onClick={handleResetDoorAccess}
                    >
                      <Iconify icon="solar:lock-keyhole-bold-duotone" />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <IconButton onClick={popover.onOpen}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </>
          }
        />

        <Card
          sx={{
            mb: 3,
            height: 290,
          }}
        >
          <ProfileCover
            role={member?.roles?.length ? t(member?.roles![0]) : ''}
            name={member?.fullName!}
            avatarUrl={member?.profileImageUrl ?? ''}
            coverUrl={coverUrl}
          />

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end',
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {currentTab === 'profile' && (
          <ProfileHome member={member} events={events} stats={memberStats} comments={comments} />
        )}

        {currentTab === 'training' && (
          <ProfileTraining
            member={member}
            coverUrl={coverUrl}
            memberships={memberships}
            chain={chain}
          />
        )}

        {currentTab === 'health' && (
          <ProfileHealth member={member} exerciseResults={exerciseResults} />
        )}

        {(chain.subscriptionType === SubscriptionType.Premium ||
          chain.subscriptionType === SubscriptionType.Platinum) &&
          currentTab === 'document' && <PersonalDocument member={member} />}

        {currentTab === 'billing' && (
          <AccountBilling
            plans={memberships}
            cards={paymentMethods}
            invoices={invoices}
            member={member}
            entries={memberBalance}
            addressBook={[member.address ?? {}]}
            handleAddNewMembership={handleAddNewMembership}
          />
        )}

        {currentTab === 'accounts' && <SubAccountList subAccounts={member.subAccounts ?? []} />}
      </Container>
      <CustomPopover open={popover.open} onClose={popover.onClose}>
        <MenuItem component={RouterLink} href={paths.members.edit(member?.id ?? -1)}>
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>
        <MenuItem onClick={handleResetPassword}>
          <Iconify icon="mdi:password-reset" />
          {t('Reset password')}
        </MenuItem>
        <MenuItem onClick={handleTurnIntoEmployee}>
          <Iconify icon="clarity:employee-solid" />
          {t('Promote to employee')}
        </MenuItem>
        <MenuItem onClick={handleLinkToExternal}>
          <Iconify icon="ic:round-launch" />
          {t('Go to external')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={handleDelete}>
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
