import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { IReportTableFilters } from '../../../types/report';
import { useLocales } from '../../../locales';
import { getMemberChurnReport } from '../../../redux/slices/report';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber, fShortenNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { IInsightTableFilters } from '../../../types/insight';
import { getMemberLeg, getMemberLifetime } from '../../../redux/slices/insight';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().startOf('year'),
  toDate: DateTime.now().endOf('year'),
};

// ----------------------------------------------------------------------

export default function LengthOfEngagementView({ fromDate, toDate }: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const report = useSelector((state) => state.insight.memberLeg);

  useEffect(() => {
    dispatch(
      getMemberLeg({
        fromDate,
        toDate,
      })
    );
  }, [dispatch, fromDate, toDate]);

  const chart = {
    series:
      report.series?.map((p) => ({
        name: p.name,
        data:
          p.data?.map((d: any) => ({
            x: d.x,
            y: d.y,
          })) ?? [],
      })) ?? [],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      labels: {
        formatter: (value) => DateTime.fromISO(value).toFormat('MMMM yyyy'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fShortenNumber(value)}`,
      },
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Length of engagement')}
          subheader={t(
            'Shows the length of engagement for members per month with a rolling average of 6 months to avoid spikes. Included membership types are contingent, campaign, punch cards, and personal training.'
          )}
          action={<> </>}
        />
        <CardContent>
          <Chart dir="ltr" type="area" series={chart.series} options={chartOptions} />
        </CardContent>
      </Card>
    </>
  );
}
