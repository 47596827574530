import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  AccountCreditBalanceEntryDto,
  AccountDto,
  ChartDto,
  CityStatsDto,
  CommentDto,
  CustomerDto,
  Direction,
  DocumentDto,
  EmployeeDto,
  ExerciseResultDto,
  FineDto,
  getConfigs,
  GuestDto,
  GuestService,
  InvoiceDto,
  LocationDto,
  LocationsService,
  MemberDto,
  MemberEventDto,
  MembershipDto,
  MembershipStatuses,
  MembersService,
  MemberStatisticsDto,
  MemberStatsDto,
  ParticipationStatus,
  PaymentMethodDto,
  PlaceDto,
  PlaceService,
  ProgramTemplateDto,
  serviceOptions,
  SignUpDto,
  StatisticsService,
} from '../../api';
import { IMemberRegistrationStats, IMemberStats } from '../../types/member';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  customers: [] as CustomerDto[],
  members: [] as MemberDto[],
  totalNumberOfMembers: 0,
  currentMember: {} as AccountDto,
  currentMemberStats: {} as MemberStatsDto,
  currentMemberComments: [] as CommentDto[],
  currentMemberMemberships: [] as MembershipDto[],
  currentMemberInvoices: [] as InvoiceDto[],
  currentMemberPaymentMethods: [] as PaymentMethodDto[],
  currentMemberExerciseResults: [] as ExerciseResultDto[],
  currentMemberClassRegistrations: [] as SignUpDto[],
  currentMemberFines: [] as FineDto[],
  currentTotalMemberFines: 0,
  currentTotalMemberClassRegistrations: 0,
  currentTotalMemberClassRegistrationStats: {} as IMemberRegistrationStats,
  currentMemberPersonalPrograms: [] as ProgramTemplateDto[],
  currentMemberPersonalTrainers: [] as EmployeeDto[],
  personalTrainingMemberships: [] as MembershipDto[],
  personalTrainingMembershipsTotal: [] as MembershipDto[],
  currentMemberBalance: [] as AccountCreditBalanceEntryDto[],
  stats: {
    totalActive: 0,
    totalInactive: 0,
    total: 0,
  } as IMemberStats,
  cityStats: [] as CityStatsDto[],
  memberStats: {} as MemberStatisticsDto,
  memberYearlyStats: {} as MemberStatisticsDto,
  membershipStats: {} as MemberStatisticsDto,
  membershipTypeDistributionStats: {} as ChartDto,
  membershipRetentionStats: {} as ChartDto,
  membersRetentionStats: {} as ChartDto,
  guests: [] as GuestDto[],
  currentMemberDocuments: [] as DocumentDto[],
  locations: [] as PlaceDto[],
  currentMemberEvents: [] as MemberEventDto[],
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMembersSuccess(state, action) {
      state.members = action.payload.members;
      state.totalNumberOfMembers = action.payload.totalMembers;
      state.stats = action.payload.stats;
    },
    getCustomersSuccess(state, action) {
      state.customers = action.payload.customers;
    },
    getMemberSuccess(state, action) {
      state.currentMember = action.payload;
    },
    getMemberBalanceSuccess(state, action) {
      state.currentMemberBalance = action.payload;
    },
    getCurrentMemberStatsSuccess(state, action) {
      state.currentMemberStats = action.payload;
    },
    getMemberCommentSuccess(state, action) {
      state.currentMemberComments = action.payload;
    },
    getMemberEventSuccess(state, action) {
      state.currentMemberEvents = action.payload;
    },
    getLocationsSuccess(state, action) {
      state.locations = action.payload;
    },
    getInvoicesSuccess(state, action) {
      state.currentMemberInvoices = action.payload;
    },
    getDocumentsSuccess(state, action) {
      state.currentMemberDocuments = action.payload;
    },
    getPersonalProgramsSuccess(state, action) {
      state.currentMemberPersonalPrograms = action.payload;
    },
    getPersonalTrainersSuccess(state, action) {
      state.currentMemberPersonalTrainers = action.payload;
    },
    getMembershipsSuccess(state, action) {
      state.currentMemberMemberships = action.payload;
    },
    getSignUpsSuccess(state, action) {
      state.currentMemberClassRegistrations = action.payload.registrations;
      state.currentTotalMemberClassRegistrations = action.payload.total;
      state.currentTotalMemberClassRegistrationStats = action.payload.stats;
    },
    getFinesSuccess(state, action) {
      state.currentMemberFines = action.payload.fines;
      state.currentTotalMemberFines = action.payload.total;
    },
    getPaymentMethodsSuccess(state, action) {
      state.currentMemberPaymentMethods = action.payload;
    },
    getCityStatsSuccess(state, action) {
      state.cityStats = action.payload;
    },
    getMemberStatsSuccess(state, action) {
      state.memberStats = action.payload;
    },
    getMemberYearlyStatsSuccess(state, action) {
      state.memberYearlyStats = action.payload;
    },
    getMembershipStatsSuccess(state, action) {
      state.membershipStats = action.payload;
    },
    getExerciseResultStatsSuccess(state, action) {
      state.currentMemberExerciseResults = action.payload;
    },
    getMembershipTypeDistributionStatsSuccess(state, action) {
      state.membershipTypeDistributionStats = action.payload;
    },
    getMembershipRetentionStatsSuccess(state, action) {
      state.membershipRetentionStats = action.payload;
    },
    getMembersRetentionStatsSuccess(state, action) {
      state.membersRetentionStats = action.payload;
    },
    getGuestsSuccess(state, action) {
      state.guests = action.payload;
    },
    getPersonalTrainingMembersSuccess(state, action) {
      state.personalTrainingMemberships = action.payload.memberships;
      state.personalTrainingMembershipsTotal = action.payload.totalMembers;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getMembers(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean | null;
    search?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/Members';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getMembersSuccess({
          totalMembers: parseInt(resp.headers['x-total-count'], 10),
          members: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getCustomers(
  params: {
    name?: string;
    companyName?: string;
    vatNo?: string;
    email?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/customers';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getCustomersSuccess({
          customers: resp.data,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

// ----------------------------------------------------------------------

export function getMember(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.details({
        memberId,
      });
      dispatch(slice.actions.getMemberSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberBalance(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getCreditBalance({
        memberId,
      });
      dispatch(slice.actions.getMemberBalanceSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberComments(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getComments({
        id: memberId,
      });
      dispatch(slice.actions.getMemberCommentSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberEvents(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getEvents({
        id: memberId,
      });
      dispatch(slice.actions.getMemberEventSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberInvoices(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getInvoices({
        memberId,
      });
      dispatch(slice.actions.getInvoicesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberSignUps(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    status?: ParticipationStatus | string | null;
    accountId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/sign-ups';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getSignUpsSuccess({
          total: parseInt(resp.headers['x-total-count'], 10),
          registrations: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getFines(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    status?: ParticipationStatus | string | null;
    accountId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/members/${params.accountId}/fines`;

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getFinesSuccess({
          total: parseInt(resp.headers['x-total-count'], 10),
          fines: resp.data,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberExerciseResults(
  memberId: number,
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getExerciseResults({
        memberId,
        ...params,
      });
      dispatch(slice.actions.getExerciseResultStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberPaymentMethods(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getPaymentMethods({
        memberId,
      });
      dispatch(slice.actions.getPaymentMethodsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getCityStats(
  params: {
    count?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.citiesStatistics(params);
      dispatch(slice.actions.getCityStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getCurrentMemberStats(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getStats({ memberId });
      dispatch(slice.actions.getCurrentMemberStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberships(
  params: {
    /**  */
    id: number;
    /**  */
    statuses?: MembershipStatuses[];
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getMemberships(params);
      dispatch(slice.actions.getMembershipsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getDocuments(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getDocuments({ memberId });
      dispatch(slice.actions.getDocumentsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getPersonalPrograms(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getPersonalPrograms({ memberId });
      dispatch(slice.actions.getPersonalProgramsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getPersonalTrainers(memberId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await MembersService.getPersonalTrainers({ memberId });
      dispatch(slice.actions.getPersonalTrainersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberStats(
  params: {
    fromDate?: DateTime;
    toDate?: DateTime;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.memberStatistics(params);
      dispatch(slice.actions.getMemberStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberYearlyStats(fromDate: DateTime, toDate: DateTime) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.memberStatistics({
        fromDate,
        toDate,
      });
      dispatch(slice.actions.getMemberYearlyStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMembershipStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.membershipStatistics();
      dispatch(slice.actions.getMembershipStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMembershipTypeDistributionStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.membershipTypeDistributionStatistics();
      dispatch(slice.actions.getMembershipTypeDistributionStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMembershipRetentionStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.membershipRetention();
      dispatch(slice.actions.getMembershipRetentionStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMembersRetentionStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await StatisticsService.memberRetention();
      dispatch(slice.actions.getMembersRetentionStatsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getGuests() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await GuestService.index();
      dispatch(slice.actions.getGuestsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMembersWithPersonalTrainingMembership(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean | null;
    search?: string | null;
    validOn?: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/personal-trainings';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getPersonalTrainingMembersSuccess({
          totalMembers: parseInt(resp.headers['x-total-count'], 10),
          memberships: resp.data,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getLocations() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await PlaceService.findAll({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      });
      dispatch(slice.actions.getLocationsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}
