// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// types
import { MemberChangeDto, MemberDto, MembershipDto } from '../../../api';
//
import { useLocales } from '../../../locales';
import Label from '../../../components/label';
import { fDate } from '../../../utils/format-time';

// ----------------------------------------------------------------------

type Props = {
  row: MemberChangeDto;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
};

export default function MemberChangeTableRow({ row, onSelectRow, onViewRow }: Props) {
  const { ownerName, ownerProfileImageUrl, validFrom, name, ownerEmail, ownerPhoneNumber } = row;

  const confirm = useBoolean();

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <>
      <TableRow hover>
        <TableCell
          onClick={onViewRow}
          sx={[
            {
              '&:hover': {
                cursor: 'pointer',
              },
            },
            { display: 'flex', alignItems: 'center' },
          ]}
        >
          <Avatar alt={ownerName} src={ownerProfileImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={ownerName}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {name}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {ownerEmail}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {ownerPhoneNumber}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {fDate(validFrom)}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
