import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setError } from 'src/redux/slices/error';

// @mui
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// utils
import { DateTime } from 'luxon';
// api
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// types
import { ICalendarEvent } from 'src/types/calendar';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getStaff } from '../../redux/slices/employees';
import { ShiftDto, ShiftsService } from '../../api';

// ----------------------------------------------------------------------

type Props = {
  colorOptions: string[];
  onClose: VoidFunction;
  currentEvent?: ICalendarEvent;
  currentShift?: ShiftDto;
};

export default function CalendarShiftForm({
  currentShift,
  currentEvent,
  colorOptions,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const instructors = useSelector((state) => state.employee.employees);

  useEffect(() => {
    dispatch(
      getStaff({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        isActive: true,
      })
    );
  }, [dispatch]);

  const EventSchema = Yup.object().shape({
    description: Yup.string().required(t('Type is required')),
    begins: Yup.mixed<any>()
      .nullable()
      .required(t('Begins is required'))
      .test('is-set', t('Begins is required'), (value, { parent }) => value),
    ends: Yup.mixed<any>()
      .nullable()
      .test(
        'date-min',
        t('Ends must be later than begins'),
        (value, { parent }) => !value || value > parent.begins
      ),
    // not required
    staffMemberId: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number())),
    numberOfRepetitions: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Repetitions needs to be at least 1'))
    ),
  });

  const defaultValues = useMemo(
    () => ({
      description: currentShift?.description || '',
      begins:
        currentShift?.begins ||
        (currentEvent?.start ? DateTime.fromJSDate(new Date(currentEvent?.start)) : DateTime.now()),
      ends:
        currentShift?.ends ||
        (currentEvent?.end ? DateTime.fromJSDate(new Date(currentEvent?.end)) : DateTime.now()),
      staffMemberId: currentShift?.employeeId || '',
      numberOfRepetitions: '',
    }),
    [currentShift, currentEvent]
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentShift) {
        await ShiftsService.update({
          id: currentShift!.id!,
          body: {
            id: currentShift!.id!,
            ...data,
          } as any,
        });
      } else {
        await ShiftsService.create({
          body: {
            ...data,
          } as any,
        });
      }

      enqueueSnackbar(currentShift ? t('Update success!') : t('Create success!'));

      onClose();
      reset();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleEmployeeChange = (event: ChangeEvent<any>) => {
    setValue('staffMemberId', event.target.value, { shouldValidate: true });
  };

  const onDelete = useCallback(async () => {
    try {
      await ShiftsService.remove({
        id: currentShift?.id ?? -1,
      });
      enqueueSnackbar(t('Delete success!'));
      onClose();
    } catch (error) {
      dispatch(setError(error));
    }
  }, [currentShift?.id, enqueueSnackbar, onClose, t, dispatch]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <RHFTextField name="description" label={t('Description')} />

        <TextField
          select
          label={t('Employee')}
          value={values.staffMemberId}
          onChange={handleEmployeeChange}
        >
          {instructors.map((option) => (
            <MenuItem key={`in-${option.id}`} value={option.id!}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <Controller
          name="begins"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              {...field}
              value={field.value}
              onChange={(newValue) => {
                if (newValue) {
                  field.onChange(newValue);
                }
              }}
              label={t('Begins')}
              format="dd/MM/yyyy HH:mm"
              ampm={false}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          )}
        />

        <Controller
          name="ends"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              {...field}
              value={field.value}
              onChange={(newValue) => {
                if (newValue) {
                  field.onChange(newValue);
                }
              }}
              label={t('Ends')}
              format="dd/MM/yyyy HH:mm"
              ampm={false}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          )}
        />

        {currentShift === undefined && (
          <RHFTextField name="numberOfRepetitions" label={t('Number of repetitions')} />
        )}
      </Stack>

      <DialogActions>
        {!!currentShift?.id && (
          <Tooltip title="Delete Event">
            <IconButton onClick={onDelete}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {t('Save Changes')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
