import { useCallback, useEffect, useState } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha, useTheme } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import { useRouter } from 'src/routes/hook';
import isEqual from 'lodash/isEqual';
//
import { Direction, EmailTemplatesService, EmailTemplateType } from '../../../api';
import { useLocales } from '../../../locales';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
import { useSettingsContext } from '../../../components/settings';
import { useBoolean } from '../../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { RouterLink } from '../../../routes/components';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import EmailTemplateTableRow from '../email-template-table-row';
import { getEmailTemplates } from '../../../redux/slices/email-template';
import { IEmailTemplateFilters, IEmailTemplateFilterValue } from '../../../types/email-template';
import EmailTemplateFiltersResult from '../email-template-filters-result';
import EmailTemplateTableToolbar from '../email-template-table-toolbar';

// ----------------------------------------------------------------------

const defaultFilters: IEmailTemplateFilters = {
  type: 'all',
};

// ----------------------------------------------------------------------

export default function EmailTemplateList() {
  const theme = useTheme();

  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'name', label: t('Name'), align: 'left' },
    { id: 'type', label: t('Type'), align: 'left' },
    { id: 'modifiedAt', label: t('Last modified'), align: 'left' },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
    defaultOrder: 'desc',
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.emailTemplate.templates);

  const tableStats = useSelector((state) => state.emailTemplate.stats);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getEmailTemplates({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        type: filters.type === 'all' ? null : filters.type,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableStats.total },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length && !!filters.type;

  const handleFilters = useCallback(
    (name: string, value: IEmailTemplateFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const deleteTemplate = async () => {
        await EmailTemplatesService.remove({
          id,
        });

        dispatch(
          getEmailTemplates({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            type: filters.type === 'all' ? null : filters.type,
          })
        );
      };

      deleteTemplate();
    },
    [dispatch, rowsPerPage, page, filters, orderBy, order]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.emailTemplates.view(id));
    },
    [router]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.emailTemplates.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('type', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Email Template List')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Email Template')}`,
              href: paths.emailTemplates.root,
            },
            { name: `${t('List')}` },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.emailTemplates.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('New')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.type}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.type) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          {canReset && (
            <EmailTemplateFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              results={tableStats.total}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <EmailTemplateTableRow
                      key={row.id}
                      row={row}
                      onEditRow={() => handleEditRow(row.id!)}
                      onDeleteRow={() => handleDeleteRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableStats.total)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tableStats.total}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
