import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { IReportTableFilters } from '../../../types/report';
import { useLocales } from '../../../locales';
import { getMemberChurnReport } from '../../../redux/slices/report';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { IInsightTableFilters } from '../../../types/insight';

// ----------------------------------------------------------------------

export default function MemberChurnReportView({ fromDate, toDate }: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const popover = usePopover();

  const report = useSelector((state) => state.report.memberChurnReport);

  useEffect(() => {
    dispatch(
      getMemberChurnReport({
        fromDate,
        toDate,
      })
    );
  }, [dispatch, fromDate, toDate]);

  const chart = {
    categories: report?.churnRates?.map((x) => x.month!) ?? [],
    series: [
      {
        name: t('Churn'),
        data: report?.churnRates?.map((x) => x.count ?? 0) ?? [],
      },
    ],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories: chart.categories,
      title: {
        text: t('Month'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fNumber(value)}%`,
      },
      title: {
        text: t('Percentage'),
      },
    },
    annotations: {
      yaxis: [
        {
          y: report.medianChurnRateForPeriod ?? 0,
          borderColor: blue['400'],
          label: {
            borderColor: blue['400'],
            style: {
              color: '#fff',
              background: blue['400'],
            },
            text: t('Median'),
          },
        },
        {
          y: report.averageChurnRateForPeriod ?? 0,
          borderColor: orange['400'],
          label: {
            borderColor: orange['400'],
            style: {
              color: '#fff',
              background: orange['400'],
            },
            text: t('Average'),
          },
        },
      ],
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Churn rate')}
          subheader={t(
            'Shows the churn rate in percentage per month for the selected period. The median and average churn rates are also shown for the entire period.'
          )}
          action={<></>}
        />
        <CardContent>
          <Chart dir="ltr" type="area" series={chart.series} options={chartOptions} />
        </CardContent>
      </Card>
    </>
  );
}
