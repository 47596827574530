// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ClassTemplateNewEditForm from '../class-template-new-edit-form';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassTemplateCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create a new class template')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Class template'),
            href: paths.classTemplates.root,
          },
          { name: t('New class template') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ClassTemplateNewEditForm />
    </Container>
  );
}
