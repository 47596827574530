import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
//
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { Direction, getConfigs, MembershipStatuses, serviceOptions } from '../../../api';
import MembershipTableRow from '../membership-table-row';
import MemberTableToolbar from '../membership-table-toolbar';
import MemberTableFiltersResult from '../membership-table-filters-result';
import Label from '../../../components/label';
import { getMemberships } from '../../../redux/slices/membership';
import {
  getCampaigns,
  getContingents,
  getPersonalTrainings,
  getPunchCards,
} from '../../../redux/slices/membership-type';
import { IMembershipTableFilters, IMembershipTableFilterValue } from '../../../types/membership';
import { getChainSettings } from '../../../redux/slices/chain';

// ----------------------------------------------------------------------

const defaultFilters: IMembershipTableFilters = {
  name: '',
  role: [],
  status: MembershipStatuses.Active,
  membershipTypeIds: [],
  validFrom: null,
  validTo: null,
  validOn: null,
};

// ----------------------------------------------------------------------

export default function MembershipListView() {
  const theme = useTheme();

  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'firstName', label: t('Member'), align: 'left' },
    { id: 'membershipTypeName', label: t('Membership'), align: 'left' },
    { id: 'priceAmount', label: t('Price'), align: 'left' },
    { id: 'billable', label: t('Frequency'), align: 'left' },
    { id: 'renewalDate', label: t('Renewal date'), align: 'left' },
    { id: 'validFrom', label: t('Valid from'), align: 'left' },
    { id: 'validTo', label: t('Valid to'), align: 'left' },
    { id: 'validOn', label: t('Valid on'), align: 'left' },
    { id: 'status', label: t('Status'), align: 'left' },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.membership.memberships);

  const totalNumberOfMemberships = useSelector(
    (state) => state.membership.totalNumberOfMemberships
  );

  const tableStats = useSelector((state) => state.membership.stats);

  const campaigns = useSelector((state) => state.membershipType.campaigns);
  const contingents = useSelector((state) => state.membershipType.contingents);
  const punchCards = useSelector((state) => state.membershipType.punchCards);
  const personalTrainings = useSelector((state) => state.membershipType.personalTrainings);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedRowsPerPage = queryParams.get('rowsPerPage');
    const storedPage = queryParams.get('page');
    const storedOrderBy = queryParams.get('orderBy');
    const storedOrder = queryParams.get('order');
    const storedStatus = queryParams.get('status');
    const storedSearch = queryParams.get('search');
    const storedValidFrom = queryParams.get('validFrom');
    const storedValidTo = queryParams.get('validTo');
    const storedValidOn = queryParams.get('validOn');
    const storedMembershipTypeIds = queryParams.get('membershipTypeIds');

    if (storedRowsPerPage === null) {
      return;
    }

    setRowsPerPage(parseInt(storedRowsPerPage ?? '10', 10));
    setPage(parseInt(storedPage ?? '0', 10));
    setOrderBy(storedOrderBy ?? '');
    setOrder(storedOrder === 'asc' ? 'asc' : 'desc');

    setFilters({
      status: storedStatus ?? '',
      name: storedSearch ?? '',
      role: [],
      membershipTypeIds: storedMembershipTypeIds
        ? storedMembershipTypeIds.split(',').map((x) => parseInt(x, 10))
        : [],
      validFrom: storedValidFrom ? DateTime.fromISO(storedValidFrom) : null,
      validTo: storedValidTo ? DateTime.fromISO(storedValidTo) : null,
      validOn: storedValidOn ? DateTime.fromISO(storedValidOn) : null,
    });
  }, [setOrder, setOrderBy, setPage, setRowsPerPage]);

  useEffect(() => {
    const newurl =
      `${window.location.protocol}//${window.location.host}${window.location.pathname}` +
      `?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&status=${filters.status}` +
      `&search=${filters.name ?? ''}&validFrom=${
        filters.validFrom ? encodeURIComponent(filters.validFrom.toISO() ?? '') : ''
      }` +
      `&validTo=${filters.validTo ? encodeURIComponent(filters.validTo.toISO() ?? '') : ''}` +
      `&validOn=${filters.validOn ? encodeURIComponent(filters.validOn.toISO() ?? '') : ''}` +
      `&membershipTypeIds=${filters.membershipTypeIds ?? ''}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [rowsPerPage, page, filters, orderBy, order]);

  useEffect(() => {
    dispatch(
      getCampaigns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(
      getContingents({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(
      getPersonalTrainings({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(
      getPunchCards({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
      })
    );
    dispatch(getChainSettings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getMemberships({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        statuses: filters.status === 'all' ? [] : [filters.status as MembershipStatuses],
        membershipTypes:
          filters.membershipTypeIds.length > 0 ? filters.membershipTypeIds : undefined,
        validFrom: filters.validFrom,
        validTo: filters.validTo,
        validOn: filters.validOn,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableStats.total },
    {
      value: MembershipStatuses.Active,
      label: t(MembershipStatuses.Active),
      color: 'success',
      count: tableStats.active,
    },
    {
      value: MembershipStatuses.MissingPayment,
      label: t(MembershipStatuses.MissingPayment),
      color: 'error',
      count: tableStats.missingPayment,
    },
    {
      value: MembershipStatuses.OnHold,
      label: t(MembershipStatuses.OnHold),
      color: 'info',
      count: tableStats.onHold,
    },
    {
      value: MembershipStatuses.Cancelled,
      label: t(MembershipStatuses.Cancelled),
      color: 'warning',
      count: tableStats.cancelled,
    },
    {
      value: MembershipStatuses.InActive,
      label: t(MembershipStatuses.InActive),
      color: 'default',
      count: tableStats.inactive,
    },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const handleFilters = useCallback(
    (name: string, value: IMembershipTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleEditNewMembership = useCallback(() => {
    dispatch(
      getMemberships({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        statuses: filters.status === 'all' ? [] : [filters.status as MembershipStatuses],
        membershipTypes:
          filters.membershipTypeIds.length > 0 ? filters.membershipTypeIds : undefined,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const handleCancelMembership = useCallback(() => {
    dispatch(
      getMemberships({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        statuses: filters.status === 'all' ? [] : [filters.status as MembershipStatuses],
        membershipTypes:
          filters.membershipTypeIds.length > 0 ? filters.membershipTypeIds : undefined,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const handlePauseMembership = useCallback(() => {
    dispatch(
      getMemberships({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        statuses: filters.status === 'all' ? [] : [filters.status as MembershipStatuses],
        membershipTypes:
          filters.membershipTypeIds.length > 0 ? filters.membershipTypeIds : undefined,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const handleExport = useCallback(() => {
    const action = async () => {
      const url = '/api/memberships';

      const configs = getConfigs('get', 'application/json', url, {
        headers: {
          Accept: 'text/csv',
        },
      });

      configs.params = {
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        search: filters.name,
        statuses: filters.status === 'all' ? [] : [filters.status as MembershipStatuses],
        membershipTypes:
          filters.membershipTypeIds.length > 0 ? filters.membershipTypeIds : undefined,
        validFrom: filters.validFrom,
        validTo: filters.validTo,
        validOn: filters.validOn,
      };
      const resp = await serviceOptions.axios!.request(configs);

      // Ignore type, because API can return multiple types
      const blob = new Blob([resp.data as any], {
        type: 'text/csv',
      });
      saveAs(blob, `result.csv`);
    };
    action();
  }, [filters]);

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Membership List')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Membership')}`,
              href: paths.memberships.root,
            },
            { name: `${t('List')}` },
          ]}
          action={
            <Stack spacing={1.5} direction="row">
              <Button
                variant="outlined"
                startIcon={<Iconify icon="fluent:guest-add-20-filled" />}
                component={RouterLink}
                href={paths.guests.new}
              >
                {t('Book a guest')}
              </Button>
              <Button
                component={RouterLink}
                href={paths.members.new}
                variant="contained"
                color="primary"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                {t('New member')}
              </Button>
            </Stack>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <MemberTableToolbar
            filters={filters}
            onFilters={handleFilters}
            campaigns={campaigns}
            contingents={contingents}
            punchCards={punchCards}
            personalTrainings={personalTrainings}
            onExport={handleExport}
          />

          {canReset && (
            <MemberTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              results={totalNumberOfMemberships}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <MembershipTableRow
                      key={row.id}
                      row={row}
                      onSelectRow={() => handleViewRow(row.ownerId!)}
                      onPutOnHold={handlePauseMembership}
                      onCancelled={handleCancelMembership}
                      onEdit={handleEditNewMembership}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMemberships)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfMemberships}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------
