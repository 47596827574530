import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { setError } from 'src/redux/slices/error';
import { useDispatch } from 'src/redux/store';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Checkbox from '@mui/material/Checkbox';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { DateTime } from 'luxon';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
import FormProvider, {
  RHFEditor,
  RHFSwitch,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
// types
import {
  AccessSystemProviders,
  ChainSettingsDto,
  ExternalLockDto,
  GymDto,
  GymsService,
  LockDto,
} from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentGym?: GymDto;
  chainSettings?: ChainSettingsDto;
  locks?: ExternalLockDto[];
};

export default function GymNewEditForm({ currentGym, chainSettings, locks }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    addressLine: Yup.string().required(t('Address line is required')),
    postalNumber: Yup.string().required(t('Postal number is required')),
    city: Yup.string().required(t('City is required')),
    country: Yup.string().required(t('Country is required')),
    images: Yup.array().min(1, t('Image is required')),
    vatNo: Yup.string().required(t('VAT No is required')),
    shortDescription: Yup.string(),
    description: Yup.string(),
    externalLockIds: Yup.array(),
    bankAccount: currentGym?.isDebtCollectionAgreementSigned
      ? Yup.string().required(t('Bank account is required'))
      : Yup.string(),
    bankAccountIban: Yup.string(),
    bankAccountSwift: Yup.string(),
    openingHours: Yup.array().required(),
    paymentProviderApiKey: Yup.string().required(t('Payment provider is required')),
    isDebtCollectionAgreementSigned: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentGym?.name || '',
      vatNo: currentGym?.vatNo || '',
      addressLine: currentGym?.address?.addressLine || '',
      postalNumber: currentGym?.address?.postalNumber || '',
      city: currentGym?.address?.city || '',
      country: currentGym?.address?.country || '',
      isDebtCollectionAgreementSigned: currentGym?.isDebtCollectionAgreementSigned || false,
      images: currentGym?.images?.map((e) => e.url) || [],
      shortDescription: currentGym?.shortDescription || '',
      externalLockIds: currentGym?.externalLockIds || [],
      description: currentGym?.description || '',
      paymentProviderApiKey: currentGym?.paymentProviderApiKey || '',
      bankAccount: currentGym?.bankAccount || chainSettings?.bankAccount || '',
      bankAccountIban: currentGym?.bankAccountIban || chainSettings?.bankAccountIban || '',
      bankAccountSwift: currentGym?.bankAccountSwift || chainSettings?.bankAccountSwift || '',
      openingHours:
        currentGym?.openingHours ||
        [
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Monday',
          },
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Tuesday',
          },
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Wednesday',
          },
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Thursday',
          },
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Friday',
          },
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Saturday',
          },
          {
            from: '00:00:00',
            to: '23:59:00',
            dayOfWeek: 'Sunday',
          },
        ].map((x) => ({
          ...x,
          from: x.from ? DateTime.fromISO(`2000-01-01T${x.from}`) : null,
          to: x.to ? DateTime.fromISO(`2000-01-01T${x.to}`) : null,
        })),
    }),
    [currentGym, chainSettings]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentGym) {
      reset(defaultValues);
    }
  }, [currentGym, defaultValues, reset]);

  const handleExternalIdChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('externalLockIds', result, { shouldValidate: true });
  };

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      const body = {
        ...data,
        id: currentGym?.id,
        openingHours: data.openingHours.map((x) => ({
          ...x,
          from: x.from ? x.from.toFormat('HH:mm:ss') : null,
          to: x.to ? x.to.toFormat('HH:mm:ss') : null,
        })),
      };

      if (currentGym) {
        await GymsService.edit({
          id: currentGym!.id!,
          body,
        });
        await GymsService.logo({ id: currentGym!.id!, files: data.images ?? [] });
      } else {
        const newGym = await GymsService.create({
          body,
        });
        await GymsService.logo({ id: newGym.id!, files: data.images ?? [] });
      }

      reset();
      enqueueSnackbar(currentGym ? t('Update success!') : t('Create success!'));
      router.push(paths.gyms.root);
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const deleted = currentGym?.images?.filter((file) => file.url === inputFile) ?? [];
      for (let i = 0; i < deleted.length; i += 1) {
        const image = deleted[i];
        if (image) {
          GymsService.removeImage({ gymId: currentGym!.id!, imageId: image?.id! });
        }
      }

      const filtered = values.images?.filter((file) => file !== inputFile) ?? [];
      setValue('images', filtered);
    },
    [setValue, currentGym, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    const deleted = currentGym?.images ?? [];
    for (let i = 0; i < deleted.length; i += 1) {
      const image = deleted[i];
      if (image) {
        GymsService.removeImage({ gymId: currentGym!.id!, imageId: image?.id! });
      }
    }
    setValue('images', []);
  }, [setValue, currentGym]);

  const handleOpeningHourFromChange = useCallback(
    (newValue: Date | null, index: number) => {
      values.openingHours[index].from = newValue;

      setValue(
        'openingHours',
        values.openingHours.map((x) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const handleOpeningHourToChange = useCallback(
    (newValue: Date | null, index: number) => {
      values.openingHours[index].to = newValue;

      setValue(
        'openingHours',
        values.openingHours.map((x) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Gym name, description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="name" label={t('Gym name')} />

            <RHFTextField name="vatNo" label={t('VAT No')} />

            <RHFTextField name="shortDescription" label={t('Sub Description')} multiline rows={4} />

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Description')}</Typography>
              <RHFEditor simple name="description" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Images')}</Typography>
              <RHFUpload
                multiple
                thumbnail
                name="images"
                maxSize={3145728}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
              />
            </Stack>

            {chainSettings?.accessSystemProvider === AccessSystemProviders.JustFace && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Location ID')}</Typography>
                <Controller
                  name="externalLockIds"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        multiple
                        value={values.externalLockIds}
                        onChange={handleExternalIdChange}
                        autoWidth={false}
                      >
                        {locks?.map((option) => (
                          <MenuItem key={`desc-${option.id}`} value={option.id}>
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={values.externalLockIds!.includes(option.id)}
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
            )}

            {chainSettings?.accessSystemProvider === AccessSystemProviders.Danalock && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Lock ID')}</Typography>
                <Controller
                  name="externalLockIds"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        multiple
                        value={values.externalLockIds}
                        onChange={handleExternalIdChange}
                        autoWidth={false}
                      >
                        {locks?.map((option) => (
                          <MenuItem key={`desc-${option.id}`} value={`${option.id}`}>
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={values.externalLockIds!.includes(option.id)}
                            />
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderLocation = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Location')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('The address that the gym is placed at.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Location')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="addressLine" label={t('Address line')} />

            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="postalNumber" label={t('Postal number')} />

              <RHFTextField name="city" label={t('City')} />
            </Box>

            <RHFTextField name="country" label={t('Country')} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPayment = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Payment')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Payment information for payment provider and debt collection.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Payment')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="paymentProviderApiKey"
              label={t('Payment provider API Key')}
              placeholder="XXXXXXXXXXXXXXXX"
            />

            <RHFTextField
              disabled={!values.isDebtCollectionAgreementSigned}
              name="bankAccount"
              label={t('Bank Account')}
              placeholder="XXXX-XXXXXX"
            />

            <RHFTextField
              disabled={!values.isDebtCollectionAgreementSigned}
              name="bankAccountIban"
              label={t('Bank Account Iban')}
            />

            <RHFTextField
              disabled={!values.isDebtCollectionAgreementSigned}
              name="bankAccountSwift"
              label={t('Bank Account Swift')}
            />

            <RHFSwitch
              name="isDebtCollectionAgreementSigned"
              label={t('Is debt collection agreement signed')}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderOpeningHours = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Opening hours')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('The opening hours of the gym.')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Opening hours')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            {values.openingHours?.map((item, index) => (
              <Stack spacing={2} key={`day-of-week-${item.dayOfWeek}`}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {t(item.dayOfWeek)}
                </Typography>
                <Stack direction="row" spacing={1.5}>
                  <TimePicker
                    label={t('Open')}
                    value={item.from}
                    ampm={false}
                    onChange={(newValue) => handleOpeningHourFromChange(newValue, index)}
                  />

                  <TimePicker
                    label={t('Closed')}
                    value={item.to}
                    ampm={false}
                    onChange={(newValue) => handleOpeningHourToChange(newValue, index)}
                  />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!currentGym ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderLocation}

        {renderOpeningHours}

        {renderPayment}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
