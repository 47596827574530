import React, { useState } from 'react';
// @mui
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// utils
import { fCurrency } from 'src/utils/format-number';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { enqueueSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// types
import { CampaignMembershipTypeDto, CampaignTypesService } from '../../../api';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: CampaignMembershipTypeDto;
  onEditRow: VoidFunction;
  onViewRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CampaignTableRow({ row, onDeleteRow, onEditRow, onViewRow }: Props) {
  const { name, priceAmount, priceCurrency, createdDate, isPublic, billable } = row;

  const confirm = useBoolean();

  const purchaseLinkDialog = useBoolean();

  const [purchaseLink, setPurchaseLink] = useState<string | undefined | null>(null);

  const { t } = useLocales();

  const popover = usePopover();

  const stockPercentage = 100;

  let inventoryType = '';
  if (stockPercentage <= 0) inventoryType = t('sold out');
  else if (stockPercentage <= 0.3) inventoryType = t('low');
  else inventoryType = t('');

  const handleNewPurchaseLink = () => {
    const request = async () => {
      const result = await CampaignTypesService.createPurchaseLink({
        id: row.id!,
      });

      setPurchaseLink(result.link);
      purchaseLinkDialog.onTrue();
    };

    request();
  };

  const copyToClipHolder = () => {
    navigator.clipboard.writeText(`${purchaseLink}`);
    purchaseLinkDialog.onFalse();

    enqueueSnackbar(t('Link copied to clipboard!'));
  };

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} variant="rounded" sx={{ width: 64, height: 64, mr: 2 }} />

          <ListItemText
            disableTypography
            primary={
              <Link
                noWrap
                color="inherit"
                variant="subtitle2"
                onClick={onViewRow}
                sx={{ cursor: 'pointer' }}
              >
                {name}
              </Link>
            }
          />
        </TableCell>

        <TableCell>{isPublic ? t('Yes') : t('No')}</TableCell>

        <TableCell>
          <ListItemText
            primary={createdDate!.toFormat('dd MMM yyyy')}
            secondary={createdDate!.toFormat('HH:mm')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
          <LinearProgress
            value={stockPercentage}
            variant="determinate"
            color={
              (stockPercentage <= 0 && 'error') ||
              (stockPercentage <= 0.3 && 'warning') ||
              'success'
            }
            sx={{ mb: 1, height: 6, maxWidth: 80 }}
          />
          {inventoryType}
        </TableCell>

        <TableCell>{t(`${billable}`)}</TableCell>

        <TableCell>{fCurrency(priceAmount, priceCurrency)}</TableCell>

        <TableCell align="right">
          <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="right-top">
        <MenuItem
          onClick={() => {
            onViewRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleNewPurchaseLink();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:share-bold-duotone" />
          {t('Purchase link')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('Delete')}
          </Button>
        }
      />

      <Dialog
        fullWidth
        maxWidth="sm"
        open={purchaseLinkDialog.value}
        onClose={purchaseLinkDialog.onFalse}
      >
        <DialogTitle>{t('New purchase link')}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              paddingTop: '6px',
            }}
          >
            <TextField label={t('Purchase link')} value={purchaseLink} fullWidth />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={purchaseLinkDialog.onFalse}>{t('Cancel')}</Button>
          <Button onClick={copyToClipHolder}>{t('Copy')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
