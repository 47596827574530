import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  AverageRevenuePerMemberReportDto,
  CandlestickDto,
  ChartSegments,
  DateOnlyAreaDto,
  DateTimeAreaDto,
  InsightsService,
  MemberChangeReportDto,
  PieChartDto,
} from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  memberChangeReport: {} as MemberChangeReportDto,
  membershipDistribution: [] as PieChartDto[],
  campaignDistribution: [] as DateOnlyAreaDto[],
  contingentDistribution: [] as DateOnlyAreaDto[],
  personalTrainingDistribution: [] as DateOnlyAreaDto[],
  dropInDistribution: [] as DateOnlyAreaDto[],
  punchCardDistribution: [] as DateOnlyAreaDto[],
  ageDistribution: [] as PieChartDto[],
  genderDistribution: {} as PieChartDto,
  activeMemberReport: {} as CandlestickDto,
  activeMembershipReport: {} as CandlestickDto,
  memberLifetime: {} as DateTimeAreaDto,
  memberLifetimeValue: {} as DateOnlyAreaDto,
  memberMonthlyRecurringRevenue: {} as DateOnlyAreaDto,
  memberMonthlyRevenue: {} as DateOnlyAreaDto,
  monthlyRevenue: {} as DateOnlyAreaDto,
  monthlyRecurringRevenue: {} as DateOnlyAreaDto,
  memberMonthlyAverageRevenue: {} as DateOnlyAreaDto,
  memberLeg: {} as DateOnlyAreaDto,
  averageRevenuePerMemberReport: {} as AverageRevenuePerMemberReportDto,
  campaignMonthlyRevenue: {} as DateOnlyAreaDto,
  contingentMonthlyRevenue: {} as DateOnlyAreaDto,
  personalTrainingMonthlyRevenue: {} as DateOnlyAreaDto,
  dropInMonthlyRevenue: {} as DateOnlyAreaDto,
  finesMonthlyRevenue: {} as DateOnlyAreaDto,
  productMonthlyRevenue: {} as DateOnlyAreaDto,
  productMonthlyRevenuePerCategory: {} as DateOnlyAreaDto,
  punchCardMonthlyRevenue: {} as DateOnlyAreaDto,
};

const slice = createSlice({
  name: 'insight',
  initialState,
  reducers: {
    getActiveMemberReportSuccess(state, action) {
      state.activeMemberReport = action.payload;
    },
    getActiveMembershipReportSuccess(state, action) {
      state.activeMembershipReport = action.payload;
    },
    getMemberLifetimeSuccess(state, action) {
      state.memberLifetime = action.payload;
    },
    getMemberLifetimeValueSuccess(state, action) {
      state.memberLifetimeValue = action.payload;
    },
    getMemberMonthlyRecurringRevenueSuccess(state, action) {
      state.memberMonthlyRecurringRevenue = action.payload;
    },
    getMemberMonthlyRevenueSuccess(state, action) {
      state.memberMonthlyRevenue = action.payload;
    },
    getMonthlyRevenueSuccess(state, action) {
      state.monthlyRevenue = action.payload;
    },
    getMonthlyRecurringRevenueSuccess(state, action) {
      state.monthlyRecurringRevenue = action.payload;
    },
    getCampaignMonthlyRevenueSuccess(state, action) {
      state.campaignMonthlyRevenue = action.payload;
    },
    getContingentMonthlyRevenueSuccess(state, action) {
      state.contingentMonthlyRevenue = action.payload;
    },
    getPunchCardMonthlyRevenueSuccess(state, action) {
      state.punchCardMonthlyRevenue = action.payload;
    },
    getDropInMonthlyRevenueSuccess(state, action) {
      state.dropInMonthlyRevenue = action.payload;
    },
    getPersonalTrainingMonthlyRevenueSuccess(state, action) {
      state.personalTrainingMonthlyRevenue = action.payload;
    },
    getFinesMonthlyRevenueSuccess(state, action) {
      state.finesMonthlyRevenue = action.payload;
    },
    getProductMonthlyRevenueSuccess(state, action) {
      state.productMonthlyRevenue = action.payload;
    },
    getProductMonthlyRevenuePerCategorySuccess(state, action) {
      state.productMonthlyRevenuePerCategory = action.payload;
    },
    getMemberMonthlyAverageRevenueSuccess(state, action) {
      state.memberMonthlyAverageRevenue = action.payload;
    },
    getMemberLegSuccess(state, action) {
      state.memberLeg = action.payload;
    },
    getMembershipDistributionSuccess(state, action) {
      state.membershipDistribution = action.payload;
    },
    getCampaignDistributionSuccess(state, action) {
      state.campaignDistribution = action.payload;
    },
    getContingentDistributionSuccess(state, action) {
      state.contingentDistribution = action.payload;
    },
    getPunchCardDistributionSuccess(state, action) {
      state.punchCardDistribution = action.payload;
    },
    getPersonalTrainingDistributionSuccess(state, action) {
      state.personalTrainingDistribution = action.payload;
    },
    getDropInDistributionSuccess(state, action) {
      state.dropInDistribution = action.payload;
    },
    getAgeDistributionSuccess(state, action) {
      state.ageDistribution = action.payload;
    },
    getGenderDistributionSuccess(state, action) {
      state.genderDistribution = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getActiveMembers(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.activeMembers(params as any);
    dispatch(slice.actions.getActiveMemberReportSuccess(response));
  };
}

export function getActiveMemberships(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.activeMemberships(params as any);
    dispatch(slice.actions.getActiveMembershipReportSuccess(response));
  };
}

export function getMemberLifetime(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.memberLifetime(params as any);
    dispatch(slice.actions.getMemberLifetimeSuccess(response));
  };
}

export function getMemberLifetimeValue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.memberLifetimeValue(params as any);
    dispatch(slice.actions.getMemberLifetimeValueSuccess(response));
  };
}

export function getMemberMonthlyRecurringRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.memberMonthlyRevenue(params as any);
    dispatch(slice.actions.getMemberMonthlyRecurringRevenueSuccess(response));
  };
}

export function getMemberMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.memberMonthlyRevenue(params as any);
    dispatch(slice.actions.getMemberMonthlyRevenueSuccess(response));
  };
}

export function getMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.monthlyRevenue(params as any);
    dispatch(slice.actions.getMonthlyRevenueSuccess(response));
  };
}

export function getMonthlyRecurringRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.monthlyRecurringRevenue(params as any);
    dispatch(slice.actions.getMonthlyRecurringRevenueSuccess(response));
  };
}

export function getCampaignMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
    membershipTypeId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.membershipMonthlyRevenue({
      ...params,
      membershipType: 'Campaign',
    } as any);
    dispatch(slice.actions.getCampaignMonthlyRevenueSuccess(response));
  };
}

export function getContingentMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
    membershipTypeId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.membershipMonthlyRevenue({
      ...params,
      membershipType: 'Contingent',
    } as any);
    dispatch(slice.actions.getContingentMonthlyRevenueSuccess(response));
  };
}

export function getPunchCardMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
    membershipTypeId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.membershipMonthlyRevenue({
      ...params,
      membershipType: 'PunchCard',
    } as any);
    dispatch(slice.actions.getPunchCardMonthlyRevenueSuccess(response));
  };
}

export function getDropInMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
    membershipTypeId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.membershipMonthlyRevenue({
      ...params,
      membershipType: 'DropIn',
    } as any);
    dispatch(slice.actions.getDropInMonthlyRevenueSuccess(response));
  };
}

export function getPersonalTrainingMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
    membershipTypeId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.membershipMonthlyRevenue({
      ...params,
      membershipType: 'PersonalTraining',
    } as any);
    dispatch(slice.actions.getPersonalTrainingMonthlyRevenueSuccess(response));
  };
}

export function getFinesMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.finesMonthlyRevenue({ ...params } as any);
    dispatch(slice.actions.getFinesMonthlyRevenueSuccess(response));
  };
}

export function getProductMonthlyRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.productMonthlyRevenue(params as any);
    dispatch(slice.actions.getProductMonthlyRevenueSuccess(response));
  };
}

export function getProductMonthlyRevenuePerCategory(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
    productCategoryId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.productMonthlyRevenuePerCategory({
      ...params,
      membershipType: 'DropIn',
    } as any);
    dispatch(slice.actions.getProductMonthlyRevenuePerCategorySuccess(response));
  };
}

export function getMemberMonthlyAverageRevenue(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.memberMonthlyAverageRevenue(params as any);
    dispatch(slice.actions.getMemberMonthlyAverageRevenueSuccess(response));
  };
}

export function getMemberLeg(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.leg(params as any);
    dispatch(slice.actions.getMemberLegSuccess(response));
  };
}

export function getMembershipDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.membershipDistribution(params as any);
    dispatch(slice.actions.getMembershipDistributionSuccess(response));
  };
}

export function getCampaignDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.campaignDistribution(params as any);
    dispatch(slice.actions.getCampaignDistributionSuccess(response));
  };
}

export function getContingentDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.contingentDistribution(params as any);
    dispatch(slice.actions.getContingentDistributionSuccess(response));
  };
}

export function getPunchCardDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.punchCardDistribution(params as any);
    dispatch(slice.actions.getPunchCardDistributionSuccess(response));
  };
}

export function getPersonalTrainingDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.personalTrainingDistribution(params as any);
    dispatch(slice.actions.getPersonalTrainingDistributionSuccess(response));
  };
}

export function getDropInDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.dropInDistribution(params as any);
    dispatch(slice.actions.getDropInDistributionSuccess(response));
  };
}

export function getAgeDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.ageDistribution(params as any);
    dispatch(slice.actions.getAgeDistributionSuccess(response));
  };
}

export function getGenderDistribution(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    segment?: ChartSegments | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await InsightsService.genderDistribution(params as any);
    dispatch(slice.actions.getGenderDistributionSuccess(response));
  };
}
