import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setError } from 'src/redux/slices/error';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { CardActions } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
// routes
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
import FormProvider, {
  RHFEditor,
  RHFMultiSelect,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from 'src/components/hook-form';
// types
import { useLocales } from '../../../locales';
import { fCurrencySymbol } from '../../../utils/format-number';
import { useDispatch, useSelector } from '../../../redux/store';
import { getEmailTemplates } from '../../../redux/slices/email-template';
import { getClassDescriptions } from '../../../redux/slices/class-description';
import { getChainSettings } from '../../../redux/slices/chain';
import {
  BillableType,
  ContingentTypeDto,
  ContingentTypesService,
  DayOfWeek,
  EmailTemplateType,
  OpeningHour,
  SubscriptionType,
} from '../../../api';
import { getContracts } from '../../../redux/slices/contract';
import { defaultOpeningHours } from '../../../types/opening-hours';
import { getGyms } from '../../../redux/slices/gym';
import Iconify from '../../../components/iconify';
import { getClassTemplates } from '../../../redux/slices/class-template';

// ----------------------------------------------------------------------

type Props = {
  currentType?: ContingentTypeDto;
};

export default function ContingentNewEditForm({ currentType }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const dispatch = useDispatch();

  const globalGym = useSelector((state) => state.gym.globalGym);

  const emailTemplates = useSelector((state) => state.emailTemplate.templates);

  const classDescriptions = useSelector((state) => state.classDescription.descriptions);

  const classTemplates = useSelector((state) => state.classTemplate.templates);

  const settings = useSelector((state) => state.chain.currentSettings);

  const chain = useSelector((state) => state.chain.currentChain);

  const contracts = useSelector((state) => state.contract.contracts);

  const gyms = useSelector((state) => state.gym.gyms);

  useEffect(() => {
    dispatch(
      getEmailTemplates({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
    dispatch(
      getClassDescriptions({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
    dispatch(
      getClassTemplates({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        isActive: true,
      })
    );
    dispatch(getChainSettings());
    dispatch(
      getGyms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
    dispatch(
      getContracts({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    description: Yup.string().required(t('Description is required')),
    priceAmount: Yup.number().min(0, t('Price should not be 0.00')),
    validFrom: Yup.date().required(t('Valid from is required')),
    validTo: Yup.date()
      .nullable()
      .test(
        'date-min',
        t('Valid to must be later than valid from'),
        (value, { parent }) => !value || value > parent.validFrom
      ),
    // not required
    taxRate: Yup.number().min(0),
    taxRateInclusive: Yup.boolean(),
    numberOfSeats: Yup.mixed(),
    maximumNumberOfActiveClassRegistrations: Yup.mixed(),
    bookingInAdvanceAllowedForDays: Yup.mixed(),
    numberOfAllowedTrainingsPerPeriod: Yup.mixed(),
    shortDescription: Yup.string(),
    hasAccessToClassIds: Yup.mixed(),
    availableInGyms: Yup.array().min(currentType ? 0 : 1, t('Please select at least one gym')),
    contractId: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required(t('Contract is required'))
        : Yup.number().min(1, t('Contract is required'))
    ),
    billable: Yup.string().required(t('Billable is required')),
    hasSetupFee: Yup.boolean(),
    setupFee: Yup.number().min(0, t('Setup fee required')),
    welcomeEmailTemplateId: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required(t('Welcome email is required'))
        : Yup.number().min(1, t('Welcome email is required'))
    ),
    cancellationEmailTemplateId: Yup.number(),
    onHoldEmailTemplateId: Yup.number(),
    isPublic: Yup.boolean(),
    automaticRenewalDisabled: Yup.boolean(),
    accountNumber: Yup.mixed(),
    membershipExpiresAfterNumberOfDays: Yup.mixed(),
    updatePriceOnExistingMemberships: Yup.boolean(),
    priceIncreaseValidFrom: Yup.lazy((value, { parent }) =>
      parent.updatePriceOnExistingMemberships !== true
        ? Yup.date().nullable()
        : Yup.date().required(t('Valid from is required'))
    ),
    hasAccessToDoorSystem: Yup.boolean(),
    openingHours: Yup.array().required(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        priceIncreaseValidFrom: null as null | DateTime,
        name: currentType?.name || '',
        description: currentType?.description || '',
        shortDescription: currentType?.shortDescription || '',
        priceAmount: currentType?.priceAmount || 0,
        taxRate: currentType?.taxRate || 0,
        taxRateInclusive: currentType?.taxRateInclusive ?? false,
        validFrom: currentType?.validFrom || null,
        validTo: currentType?.validTo || null,
        gymId: currentType?.gymId || null,
        hasAccessToClassIds: currentType?.hasAccessToClassIds?.map((e) => `${e}`) || [],
        availableInGyms: [`${globalGym.id}`],
        numberOfSeats: currentType?.numberOfSeats || '',
        maximumNumberOfActiveClassRegistrations:
          currentType?.maximumNumberOfActiveClassRegistrations || '',
        bookingInAdvanceAllowedForDays: currentType?.bookingInAdvanceAllowedForDays || '',
        numberOfAllowedTrainingsPerPeriod: currentType?.numberOfAllowedTrainingsPerPeriod || '',
        priceCurrency: settings?.defaultCurrency || 'EUR',
        contractId: currentType?.contractId || -1,
        billable: currentType?.billable || BillableType.Monthly,
        hasSetupFee: currentType?.hasSetupFee ?? true,
        setupFee: currentType?.setupFee || settings.setupFeeAmount || 0,
        welcomeEmailTemplateId: currentType?.welcomeEmailTemplateId || -1,
        cancellationEmailTemplateId: currentType?.cancellationEmailTemplateId || -1,
        onHoldEmailTemplateId: currentType?.onHoldEmailTemplateId || -1,
        isPublic: currentType?.isPublic ?? true,
        automaticRenewalDisabled: currentType?.automaticRenewalDisabled ?? false,
        accountNumber: currentType?.accountNumber || '',
        membershipExpiresAfterNumberOfDays: currentType?.membershipExpiresAfterNumberOfDays || '',
        updatePriceOnExistingMemberships: currentType?.updatePriceOnExistingMemberships ?? false,
        hasAccessToDoorSystem: currentType?.hasAccessToDoorSystem || true,
        openingHours: currentType?.openingHours || [],
      } as any),
    [currentType, settings, globalGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const [hasTrainingHours, setHasTrainingHours] = useState(false);

  useEffect(() => {
    if (currentType) {
      reset(defaultValues);
      setHasTrainingHours((currentType?.openingHours?.length ?? 0) > 0);
    }
    if (settings) {
      reset(defaultValues);
    }
    if (globalGym) {
      reset(defaultValues);
    }
  }, [currentType, defaultValues, reset, settings, globalGym, setHasTrainingHours]);

  const handleOpeningHourFromChange = useCallback(
    (newValue: DateTime | null, index: number) => {
      values.openingHours[index].from = newValue;

      setValue(
        'openingHours',
        values.openingHours.map((x: OpeningHour) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const handleRemoveOpeningHour = useCallback(
    (index: number) => {
      values.openingHours.splice(index, 1);

      setValue(
        'openingHours',
        values.openingHours.map((x: OpeningHour) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const handleAddOpeningHour = useCallback(() => {
    values.openingHours.push({
      from: null,
      to: null,
      dayOfWeek: DayOfWeek.Monday,
    });

    setValue(
      'openingHours',
      values.openingHours.map((x: OpeningHour) => ({ ...x }))
    );
  }, [setValue, values]);

  const handleOpeningHourToChange = useCallback(
    (newValue: DateTime | null, index: number) => {
      values.openingHours[index].to = newValue;

      setValue(
        'openingHours',
        values.openingHours.map((x: OpeningHour) => ({ ...x }))
      );
    },
    [setValue, values]
  );

  const weekDays = [
    {
      value: DayOfWeek.Monday,
      label: t(DayOfWeek.Monday),
    },
    {
      value: DayOfWeek.Tuesday,
      label: t(DayOfWeek.Tuesday),
    },
    {
      value: DayOfWeek.Wednesday,
      label: t(DayOfWeek.Wednesday),
    },
    {
      value: DayOfWeek.Thursday,
      label: t(DayOfWeek.Thursday),
    },
    {
      value: DayOfWeek.Friday,
      label: t(DayOfWeek.Friday),
    },
    {
      value: DayOfWeek.Saturday,
      label: t(DayOfWeek.Saturday),
    },
    {
      value: DayOfWeek.Sunday,
      label: t(DayOfWeek.Sunday),
    },
  ];

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentType) {
        await ContingentTypesService.edit({
          id: currentType!.id!,
          body: { id: currentType!.id!, ...data } as any,
        });
      } else {
        await ContingentTypesService.create({ body: { ...data } as any });
      }

      reset();
      enqueueSnackbar(currentType ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleHasTrainingHours = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (hasTrainingHours) {
      setValue('openingHours', [...defaultValues.openingHours]);
    } else {
      setValue('openingHours', [...defaultOpeningHours]);
    }
    setHasTrainingHours(!hasTrainingHours);
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Name, description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="name" label={t('Name')} />

            <RHFTextField name="shortDescription" label={t('Sub Description')} multiline rows={4} />

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Description')}</Typography>
              <RHFEditor simple name="description" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Contract')}</Typography>
              <RHFSelect native name="contractId">
                <option value="-1" disabled>
                  {t('Please select a contract')}
                </option>
                {contracts.map((c) => (
                  <option key={c.name} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </RHFSelect>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Properties')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Additional functions and attributes...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Properties')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFSelect native name="billable" label={t('Frequency')}>
              <option key={BillableType.OneTime} value={BillableType.OneTime}>
                {t('OneTime')}
              </option>
              <option key={BillableType.Monthly} value={BillableType.Monthly}>
                {t('Monthly')}
              </option>
              <option key={BillableType.Quarterly} value={BillableType.Quarterly}>
                {t('Quarterly')}
              </option>
              <option key={BillableType.HalfYearly} value={BillableType.HalfYearly}>
                {t('HalfYearly')}
              </option>
              <option key={BillableType.Yearly} value={BillableType.Yearly}>
                {t('Yearly')}
              </option>
              <option key={BillableType.Weekly} value={BillableType.Weekly}>
                {t('Weekly')}
              </option>
              <option key={BillableType.BiWeekly} value={BillableType.BiWeekly}>
                {t('BiWeekly')}
              </option>
              <option key={BillableType.TriWeekly} value={BillableType.TriWeekly}>
                {t('TriWeekly')}
              </option>
              <option key={BillableType.EveryFourthWeek} value={BillableType.EveryFourthWeek}>
                {t('EveryFourthWeek')}
              </option>
              <option key={BillableType.EveryTwelfthWeek} value={BillableType.EveryTwelfthWeek}>
                {t('EveryTwelfthWeek')}
              </option>
            </RHFSelect>

            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="code" label={t('Product Code')} />

              <RHFTextField name="sku" label={t('Product SKU')} />

              <RHFTextField name="numberOfSeats" label={t('Number of seats')} />

              <RHFTextField name="accountNumber" label={t('Account number for bookkeeping')} />

              <RHFSwitch name="isPublic" label={t('Publicly available')} />

              <RHFSwitch name="hasAccessToDoorSystem" label={t('Has access to door system')} />
              {(chain.subscriptionType === SubscriptionType.Premium ||
                chain.subscriptionType === SubscriptionType.Platinum) && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasTrainingHours}
                      onChange={(e) => handleHasTrainingHours(e)}
                    />
                  }
                  label={t('Limit training hours')}
                />
              )}
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderTraining = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Training')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Class properties and access...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Training')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="maximumNumberOfActiveClassRegistrations"
                label={t('Maximum number of active bookings')}
              />

              <RHFTextField
                name="bookingInAdvanceAllowedForDays"
                label={t('Number days in advance for bookings')}
              />

              <RHFTextField
                name="numberOfAllowedTrainingsPerPeriod"
                label={t('Maximum number of bookings per period')}
              />

              <RHFMultiSelect
                name="hasAccessToClassIds"
                label={t('Allowed classes')}
                options={classDescriptions.map((e) => ({ value: `${e.id}`, label: e.name! }))}
                checkbox
              />
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderCommunication = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Communication')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Emails related to membership lifespan...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Communication')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFSelect native name="welcomeEmailTemplateId" label={t('Welcome email')}>
                <option value="-1" disabled>
                  {t('Please select a template')}
                </option>
                {emailTemplates
                  .filter((e) => e.type === EmailTemplateType.WelcomeEmail)
                  .map((c) => (
                    <option key={c.name} value={c.id}>
                      {c.name}
                    </option>
                  ))}
              </RHFSelect>
              <RHFSelect native name="cancellationEmailTemplateId" label={t('Cancellation email')}>
                <option value="-1" disabled>
                  {t('Please select a template')}
                </option>
                {emailTemplates
                  .filter((e) => e.type === EmailTemplateType.Cancellation)
                  .map((c) => (
                    <option key={c.name} value={c.id}>
                      {c.name}
                    </option>
                  ))}
              </RHFSelect>
              <RHFSelect native name="onHoldEmailTemplateId" label={t('On hold email')}>
                <option value="-1" disabled>
                  {t('Please select a template')}
                </option>
                {emailTemplates
                  .filter((e) => e.type === EmailTemplateType.PutOnHold)
                  .map((c) => (
                    <option key={c.name} value={c.id}>
                      {c.name}
                    </option>
                  ))}
              </RHFSelect>
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPricing = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Pricing
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Price related inputs
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Pricing" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="priceAmount"
              label={t('Regular Price')}
              placeholder="0.00"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      {fCurrencySymbol(defaultValues.priceCurrency)}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            {currentType && (
              <Box
                columnGap={2}
                rowGap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                }}
              >
                <RHFSwitch name="updatePriceOnExistingMemberships" label={t('Price increase')} />
                <Controller
                  name="priceIncreaseValidFrom"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      {...field}
                      label={t('Price increase on')}
                      value={field.value}
                      onChange={(newValue) => {
                        if (newValue) {
                          field.onChange(newValue.toISO());
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </Box>
            )}

            <RHFSwitch name="hasSetupFee" label={t('Setup fee')} />

            {values.hasSetupFee && (
              <RHFTextField
                name="setupFee"
                label={t('Setup fee amount')}
                placeholder="0.00"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span" sx={{ color: 'text.disabled' }}>
                        {fCurrencySymbol(defaultValues.priceCurrency)}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <RHFSwitch name="taxRateInclusive" label={t('Price includes taxes')} />

            <RHFTextField
              name="taxRate"
              label={t('Tax (%)')}
              placeholder="0.00"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component="span" sx={{ color: 'text.disabled' }}>
                      %
                    </Box>
                  </InputAdornment>
                ),
              }}
            />

            {!currentType && (
              <RHFMultiSelect
                name="availableInGyms"
                label={t('Available in gyms')}
                options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
                checkbox
              />
            )}

            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <Controller
                name="validFrom"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('Can be purchased from')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="validTo"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('Can be purchased until')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderOpeningHours = (
    <>
      {hasTrainingHours && (
        <>
          {mdUp && (
            <Grid md={4}>
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                {t('Training hours')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t(
                  'The training hours that is possible with this membership. Effects the door access. If none is set then the system uses the opening hours of the gym.'
                )}
              </Typography>
            </Grid>
          )}

          <Grid xs={12} md={8}>
            <Card>
              {!mdUp && <CardHeader title={t('Opening hours')} />}

              <Stack spacing={3} sx={{ p: 3 }}>
                {values.openingHours?.map((item: OpeningHour, index: number) => (
                  <Stack spacing={2} key={`day-of-week-${item.dayOfWeek}-${index}`} direction="row">
                    <Box
                      columnGap={2}
                      rowGap={3}
                      display="grid"
                      gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        md: 'repeat(3, 1fr)',
                      }}
                    >
                      <RHFSelect name={`openingHours[${index}].dayOfWeek`} label={t('Day of week')}>
                        {weekDays.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </RHFSelect>

                      <TimePicker
                        label={t('Open')}
                        value={item.from}
                        ampm={false}
                        onChange={(newValue) =>
                          handleOpeningHourFromChange(newValue as DateTime, index)
                        }
                      />

                      <TimePicker
                        label={t('Closed')}
                        value={item.to}
                        ampm={false}
                        onChange={(newValue) =>
                          handleOpeningHourToChange(newValue as DateTime, index)
                        }
                      />
                    </Box>

                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleRemoveOpeningHour(index)}
                      sx={{
                        width: 25,
                        height: 25,
                        marginTop: '15px',
                        bgcolor: 'error.main',
                        color: 'error.contrastText',
                        '&:hover': {
                          bgcolor: 'error.dark',
                        },
                      }}
                    >
                      <Iconify icon="mingcute:close-line" />
                    </IconButton>
                  </Stack>
                ))}
              </Stack>

              <CardActions>
                <Button onClick={handleAddOpeningHour}>{t('Add opening hour')}</Button>
              </CardActions>
            </Card>
          </Grid>
        </>
      )}
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!currentType ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderProperties}

        {renderTraining}

        {renderOpeningHours}

        {renderCommunication}

        {renderPricing}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
