import * as Yup from 'yup';
import 'yup-phone-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { setError } from 'src/redux/slices/error';
import { useDispatch, useSelector } from 'src/redux/store';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// utils
import { fData } from 'src/utils/format-number';
import { CountryCode } from 'libphonenumber-js/types';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// types
// assets
// components
import { MuiColorInput } from 'mui-color-input';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFEditor,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { EmployeeDto, EmployeesService, RoleDto } from '../../api';
import { useLocales } from '../../locales';
import { countries } from '../../assets/data';
import { useAuthContext } from '../../auth/hooks';
import { getGyms } from '../../redux/slices/gym';

// ----------------------------------------------------------------------

type Props = {
  currentEmployee?: EmployeeDto;
  roles: RoleDto[];
};

export default function EmployeeNewEditForm({ currentEmployee, roles }: Props) {
  const router = useRouter();

  const { user } = useAuthContext();

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [countryCode, setCountryCode] = useState<CountryCode>('DK');

  const gyms = useSelector((state) => state.gym.gyms);

  const selectedGym = useSelector((state) => state.gym.globalGym);

  const newSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First name is required')),
    lastName: Yup.string().required(t('Last name is required')),
    middleName: Yup.string(),
    profileImageUrl: Yup.mixed(),
    dateOfBirth: Yup.date().required(t('Date of birth is required')),
    phoneNumber: Yup.string().phone(countryCode).required(t('Phone number is required')),
    email: Yup.string()
      .required(t('Email is required'))
      .email(t('Email must be a valid email address')),
    gender: Yup.string().required(t('Gender is required')),
    addressLine: Yup.string().required(t('Address line is required')),
    city: Yup.string().required(t('City is required')),
    postalNumber: Yup.string().required(t('Postal number is required')),
    country: Yup.string().required(t('Country is required')),
    roles: Yup.array().min(0, t('At least one role is required')),
    gymId: Yup.number().min(1, t('Gym is required')),
    tint: Yup.string().required(t('Tint is required')),
    title: Yup.string(),
    profileText: Yup.string(),
    receiveNotificationAboutPurchases: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        id: currentEmployee?.id,
        firstName: currentEmployee?.firstName || '',
        lastName: currentEmployee?.lastName || '',
        middleName: currentEmployee?.middleName || '',
        profileImageUrl: currentEmployee?.profileImageUrl || '',
        dateOfBirth: currentEmployee?.dateOfBirth,
        phoneNumber: currentEmployee?.phoneNumber || '',
        email: currentEmployee?.email || '',
        gender: currentEmployee?.gender || '',
        roles: currentEmployee?.roles || [],
        gymId: currentEmployee?.gymId || selectedGym?.id || (gyms.length > 0 ? gyms[0].id : -1),
        addressLine: currentEmployee?.address?.addressLine || '',
        city: currentEmployee?.address?.city || '',
        postalNumber: currentEmployee?.address?.postalNumber || '',
        country: currentEmployee?.address?.country || '',
        tint: currentEmployee?.tint || '#E1E1E1',
        title: currentEmployee?.title || '',
        profileText: currentEmployee?.profileText || '',
        receiveNotificationAboutPurchases:
          currentEmployee?.receiveNotificationAboutPurchases || false,
      } as any),
    [currentEmployee, gyms, selectedGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getGyms({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (currentEmployee) {
      reset(defaultValues);
      const result = countries.filter((x) => x.label === currentEmployee.address?.country);
      if (result.length) {
        setCountryCode(result[0].code as CountryCode);
      }
    }
  }, [currentEmployee, defaultValues, reset]);

  const handleDelete = async () => {
    await EmployeesService.delete({
      id: currentEmployee?.id!,
    });

    router.push(paths.employee.root);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      let employeeId = currentEmployee?.id;
      if (currentEmployee === undefined) {
        const result = await EmployeesService.create({
          body: data as any,
        });
        employeeId = result.id;
        if (data.profileImageUrl && data.profileImageUrl !== values.profileImageUrl) {
          await EmployeesService.profileImage({
            id: employeeId!,
            files: [data.profileImageUrl] ?? [],
          });
        }
      } else {
        await EmployeesService.edit({
          id: employeeId!,
          body: { ...data, id: employeeId! } as any,
        });
        if (data.profileImageUrl && data.profileImageUrl !== currentEmployee.profileImageUrl) {
          await EmployeesService.profileImage({
            id: employeeId!,
            files: [data.profileImageUrl] ?? [],
          });
        }
      }

      reset();
      enqueueSnackbar(currentEmployee ? t('Update success!') : t('Create success!'));
      router.push(paths.employee.details(employeeId!));
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const handleGenderChange = (event: SelectChangeEvent<any>) => {
    setValue('gender', event.target.value, { shouldValidate: true });
  };

  const handleGymChange = (event: SelectChangeEvent<any>) => {
    setValue('gymId', event.target.value, { shouldValidate: true });
  };

  const handleRoleChange = (event: SelectChangeEvent<any[]>) => {
    const result =
      typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
    setValue('roles', result, { shouldValidate: true });
  };

  const handleTintChange = (newValue: string) => {
    setValue('tint', newValue);
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profileImageUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleCountrySelect = useCallback(
    (value: any) => {
      setCountryCode(value.code);
      setValue('country', value.label, { shouldValidate: true });
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="profileImageUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    {t('Allowed *.jpeg, *.jpg, *.png, *.gif')}
                    <br /> {t('max size of {{size}}', { size: fData(3145728) })}
                  </Typography>
                }
              />
            </Box>

            {currentEmployee && (
              <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                <Button variant="soft" color="error" onClick={handleDelete}>
                  {t('Delete Employee')}
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
            >
              <RHFTextField name="firstName" label={t('First Name')} />
              <RHFTextField name="middleName" label={t('Middle Name')} />
              <RHFTextField name="lastName" label={t('Last Name')} />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{
                mt: 3,
              }}
            >
              <RHFTextField name="email" label={t('Email address')} />
              <RHFTextField name="phoneNumber" label={t('Phone Number')} />

              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={t('Date of birth')}
                    {...field}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Gender')}</InputLabel>
                    <Select
                      label={t('Gender')}
                      value={values.gender}
                      onChange={handleGenderChange}
                      autoWidth={false}
                    >
                      <MenuItem key="Male" value="Male">
                        {t('Male')}
                      </MenuItem>
                      <MenuItem key="Female" value="Female">
                        {t('Female')}
                      </MenuItem>
                      <MenuItem key="Unspecified" value="Unspecified">
                        {t('Unspecified')}
                      </MenuItem>
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />

              <MuiColorInput
                label={t('Tint')}
                value={values.tint}
                onChange={handleTintChange}
                format="hex"
              />

              <Controller
                name="roles"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Roles')}</InputLabel>
                    <Select
                      multiple
                      label={t('Roles')}
                      value={values.roles}
                      renderValue={(selected) => selected.map((value) => t(value)).join(', ')}
                      onChange={handleRoleChange}
                      autoWidth={false}
                    >
                      {roles &&
                        roles.map((r) => (
                          <MenuItem
                            key={r.value!}
                            value={r.value!}
                            disabled={
                              r.value === 'Owner' &&
                              (user?.roles.filter((x: any) => x === 'Owner').length === 0 ?? true)
                            }
                          >
                            <Checkbox
                              disableRipple
                              size="small"
                              checked={values.roles!.includes(r.value!)}
                            />
                            {t(r.text!)}
                          </MenuItem>
                        ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
              }}
              sx={{
                mt: 3,
              }}
            >
              <Controller
                name="gymId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Gym')}</InputLabel>
                    <Select
                      value={values.gymId}
                      label={t('Gym')}
                      onChange={handleGymChange}
                      autoWidth={false}
                    >
                      {gyms.map((option) => (
                        <MenuItem key={`gym-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Box>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{
                mt: 3,
              }}
            >
              <RHFTextField name="addressLine" label={t('Address line')} />
              <RHFTextField name="postalNumber" label={t('Postal number')} />
              <RHFTextField name="city" label={t('City')} />
              <Autocomplete
                value={{ label: values.country } as any}
                options={countries}
                autoHighlight
                onChange={(event: any, newValue) => {
                  handleCountrySelect(newValue);
                }}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Choose a country')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Box>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              sx={{
                mt: 3,
              }}
            >
              <RHFTextField name="title" label={t('Title')} />

              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Profile text')}</Typography>
                <RHFEditor simple name="profileText" />
              </Stack>

              <RHFSwitch
                name="receiveNotificationAboutPurchases"
                label={t('Receive notification about purchases')}
              />
            </Box>
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentEmployee ? t('Create') : t('Save Changes')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
