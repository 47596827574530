import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import MemberLengthOfEngagementReportToolbar from './member-length-of-engagement-report-toolbar';

import { IReportFilterValue, IReportTableFilters } from '../../../types/report';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import Scrollbar from '../../../components/scrollbar';
import { getMemberLengthOfEngagementReport } from '../../../redux/slices/report';
import MemberLengthOfEngagementTableRow from './member-length-of-engagement-table-row';
import { useRouter } from '../../../routes/hook';
import { convertToCSV } from '../../../utils/array-utils';
import { fShortenNumber } from '../../../utils/format-number';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

// ----------------------------------------------------------------------

export default function MemberLengthOfEngagementReportView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const router = useRouter();

  const tableHeadNewMembers = [
    { id: 'name', label: t('Member'), align: 'left' },
    { id: 'email', label: t('Email'), align: 'left' },
    { id: 'phoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'lengthOfEngagement', label: t('Length of engagement'), align: 'left' },
    { id: '' },
  ];

  const tableNewMembers = useTable({
    defaultOrderBy: tableHeadNewMembers[0].id,
  });

  const report = useSelector((state) => state.report.memberLengthOfEngagementReport);
  const newTableData = report.members ?? [];
  const newNotFound = !newTableData.length;

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(getMemberLengthOfEngagementReport(filters));
  }, [dispatch, filters]);

  const handleFilters = useCallback(
    (name: string, value: IReportFilterValue) => {
      tableNewMembers.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [tableNewMembers]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const exportCsv = useCallback(() => {
    const newMembers = convertToCSV(
      (report.members ?? []).map((el) => ({
        id: el.id,
        name: el.name ?? '',
        email: el.email ?? '',
        phoneNumber: el.phoneNumber ?? '',
        lengthOfEngagement: el.lengthOfEngagement ?? 0,
      }))
    );

    // Ignore type, because API can return multiple types
    const newBlob = new Blob([newMembers as any], {
      type: 'text/csv',
    });
    saveAs(newBlob, `report.csv`);
  }, [report]);

  const handlePrintPage: VoidFunction = () => window.print();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Length of engagement report')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Reports')}`,
            href: paths.report.root,
          },
          { name: `${t('Length of engagement report')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} id="printarea">
        <Card>
          <MemberLengthOfEngagementReportToolbar
            filters={filters}
            onFilters={handleFilters}
            onPrint={handlePrintPage}
            onExport={exportCsv}
          />
        </Card>
        <Card>
          <CardHeader title={t('Overview')} />
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <th align="left">{t('Length of engagement in months')}</th>
                  <td align="right">{fShortenNumber(report.lengthOfEngagement ?? 0)}</td>
                </tr>
              </tbody>
            </table>
          </CardContent>
        </Card>

        <Card>
          <CardHeader title={t('New members')} />
          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              pt: 2.5,
            }}
          >
            <Scrollbar>
              <Table size={tableNewMembers.dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={tableNewMembers.order}
                  orderBy={tableNewMembers.orderBy}
                  headLabel={tableHeadNewMembers}
                  rowCount={newTableData.length}
                  numSelected={tableNewMembers.selected.length}
                  onSort={tableNewMembers.onSort}
                />

                <TableBody>
                  {newTableData?.map((row) => (
                    <MemberLengthOfEngagementTableRow
                      key={row.id}
                      row={row}
                      onViewRow={() => handleViewRow(row.id!)}
                      onSelectRow={() => handleViewRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    emptyRows={emptyRows(
                      tableNewMembers.page,
                      tableNewMembers.rowsPerPage,
                      newTableData.length
                    )}
                  />

                  <TableNoData notFound={newNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={newTableData.length}
            page={tableNewMembers.page}
            rowsPerPage={25}
            onPageChange={tableNewMembers.onChangePage}
            onRowsPerPageChange={tableNewMembers.onChangeRowsPerPage}
          />
        </Card>
      </Stack>
    </Container>
  );
}
