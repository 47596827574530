import Calendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import luxonPlugin from '@fullcalendar/luxon3';
//
import React, { useCallback, useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// api
import { updateEvent } from 'src/api/calendar';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
// types
import { ICalendarEvent, ICalendarFilters, ICalendarFilterValue } from 'src/types/calendar';
//
import { EventClickArg, EventContentArg } from '@fullcalendar/core';
import { StyledCalendar } from '../styles';
import CalendarClazzForm from '../calendar-clazz-form';
import CalendarToolbar from '../calendar-toolbar';
import CalendarFiltersResult from '../calendar-filters-result';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import { RouterLink } from '../../../routes/components';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useDispatch, useSelector } from '../../../redux/store';
import { getClasses } from '../../../redux/slices/classes';
import { useRouter } from '../../../routes/hook';
import { useCalendar, useEvent } from '../../calendar/hooks';
import CalendarPersonalTrainingForm from '../calendar-personal-training-form';
import { ClazzDto, Direction, LocationDto, ParticipationStatus } from '../../../api';
import { getLocations } from '../../../redux/slices/gym';

// ----------------------------------------------------------------------

const defaultFilters: ICalendarFilters = {
  colors: [],
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------

export default function ClassesListView() {
  const theme = useTheme();

  const settings = useSettingsContext();

  const { t } = useLocales();

  const smUp = useResponsive('up', 'sm');

  const dispatch = useDispatch();

  const [filters, setFilters] = useState(defaultFilters);

  const classes = useSelector((state) => state.clazz.classes);

  const eventsLoading = useSelector((state) => state.clazz.isLoading);

  const selectedGym = useSelector((state) => state.gym.globalGym);

  const locations = useSelector((state) => state.gym.locations);

  const [selectedLocation, setSelectedLocation] = useState<LocationDto | null>(null);

  const events = classes
    .map(
      (e) =>
        ({
          id: `${e.id}`,
          color: e.tint ?? '',
          title: e.title ?? '',
          allDay: e.isOpenGym ?? false,
          description: e.description?.description ?? '',
          end: e.ends?.toString(),
          start: e.begins?.toString(),
          extendedProps: {
            clazz: e,
          },
        } as ICalendarEvent)
    )
    .map((e) => ({
      ...e,
      textColor: e.color,
    }));

  const queryParams = new URLSearchParams(window.location.search);
  const storedDate = queryParams.get('date') ? decodeURIComponent(queryParams.get('date')!) : null;

  const {
    calendarRef,
    //
    view,
    date,
    startDate,
    endDate,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onChangeView,
    onSelectRange,
    onResizeEvent,
    //
    openForm,
    onCloseForm,
    //
    selectEventId,
    selectedRange,
  } = useCalendar({
    defaultDate:
      storedDate !== null
        ? DateTime.fromISO(storedDate, {
            zone: 'local',
          })
        : undefined,
  });

  const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);

  useEffect(() => {
    dispatch(
      getClasses({
        from: startDate,
        to: endDate,
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        selectedLocationId: selectedLocation?.id,
      })
    );
    dispatch(
      getLocations({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        sortBy: ['name'],
        direction: Direction.Asc,
      })
    );
  }, [dispatch, startDate, endDate, selectedGym, selectedLocation]);

  useEffect(() => {
    const newurl =
      `${window.location.protocol}//${window.location.host}${window.location.pathname}` +
      `?date=${date ? encodeURIComponent(date.toISO({ includeOffset: false })!) : ''}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [date, selectedGym]);

  const formTypes = ['Class', 'Personal training'];

  const [formType, setFormType] = useState(formTypes[0]);

  const handleFilters = useCallback((name: string, value: ICalendarFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const onChangeLocation = useCallback((newLocation: LocationDto | null) => {
    setSelectedLocation(newLocation);
  }, []);

  const canReset = !!filters.colors.length || (!!filters.startDate && !!filters.endDate);

  const renderResults = (
    <CalendarFiltersResult
      filters={filters}
      onFilters={handleFilters}
      //
      canReset={canReset}
      onResetFilters={handleResetFilters}
      //
      results={events.length}
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );

  const router = useRouter();

  const onClickEvent = useCallback(
    (arg: EventClickArg) => {
      const { event } = arg;
      router.push(paths.classes.view(parseInt(event.id, 10)));
    },
    [router]
  );

  const { currentLang } = useLocales();

  const getNumberOfParticipants = (clazz: ClazzDto) =>
    clazz.participants?.filter(
      (x) =>
        x.status === ParticipationStatus.OnTime ||
        x.status === ParticipationStatus.TooLate ||
        x.status === ParticipationStatus.SignedUp
    ).length ?? 0;

  const hasWaitingList = (clazz: ClazzDto) => {
    const i =
      clazz.participants?.filter(
        (x) =>
          x.status === ParticipationStatus.OnTime ||
          x.status === ParticipationStatus.TooLate ||
          x.status === ParticipationStatus.SignedUp ||
          x.status === ParticipationStatus.OnWaitingList
      ).length ?? 0;
    const j = clazz.capacity ?? Number.MAX_VALUE;
    return i > j && clazz.isOpenGym !== true;
  };

  const renderEventContent = (arg: EventContentArg) => {
    const extendedProps = arg.event.extendedProps as any;
    const clazz = extendedProps.clazz as ClazzDto;
    return (
      <Stack>
        <Box
          className="fc-event-main-frame"
          sx={{
            flexDirection: 'column',
          }}
        >
          <div
            className="fc-event-time"
            style={{
              maxHeight: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {clazz.begins?.toFormat('HH:mm')} - {clazz.ends?.toFormat('HH:mm')}
          </div>
          <Box
            className="fc-event-title-container"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Box
              className="fc-event-title"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                height: '20px',
              }}
            >
              {clazz.title}
            </Box>

            {clazz.isOpenGym !== true && (
              <Stack spacing={0.2} sx={{ mt: 0.3 }}>
                <Box display="flex" alignItems="center" sx={{ overflow: 'hidden' }}>
                  <Iconify icon="eva:people-outline" sx={{ mr: 0.25 }} />
                  <Typography variant="subtitle2">
                    {`${getNumberOfParticipants(clazz)}/${clazz.capacity}`}
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
        </Box>
        <Stack direction="row" spacing={0.5} sx={{ mt: 0.2 }}>
          {clazz.isGuestBookingPossible && (
            <Tooltip title={t('Guests are allowed')}>
              <Avatar color="default" sx={{ width: 24, height: 24 }}>
                <Iconify icon="fluent:guest-16-filled" width={16} />
              </Avatar>
            </Tooltip>
          )}
          {hasWaitingList(clazz) && (
            <Tooltip title={t('Has waiting list')}>
              <Avatar color="warning" sx={{ width: 24, height: 24 }}>
                <Iconify icon="fluent-mdl2:waitlist-confirm" width={14} />
              </Avatar>
            </Tooltip>
          )}

          {clazz.instructors?.map((x) => (
            <Tooltip title={x.name}>
              <Avatar src={x.profileImageUrl} alt={x.name} sx={{ width: 24, height: 24 }} />
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    );
  };

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Scheduled classes')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            {
              name: t('Classes'),
              href: paths.classes.root,
            },
            { name: t('Calendar') },
          ]}
          action={
            <>
              <Button
                component={RouterLink}
                href={paths.classTemplates.new}
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                {t('Scheduling')}
              </Button>
            </>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        {canReset && renderResults}

        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date}
              view={view}
              loading={eventsLoading}
              onNextDate={onDateNext}
              onPrevDate={onDatePrev}
              onToday={onDateToday}
              onChangeView={onChangeView}
              locations={locations}
              selectedLocation={selectedLocation}
              onChangeLocation={onChangeLocation}
            />

            <Calendar
              weekends
              droppable
              selectable
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date.toJSDate()}
              initialView={view}
              dayMaxEventRows={3}
              eventDisplay="block"
              events={events}
              headerToolbar={false}
              nowIndicator
              slotDuration="00:15:00"
              locale={currentLang.value}
              select={onSelectRange}
              eventClick={onClickEvent}
              eventContent={renderEventContent}
              height={smUp ? 720 : 'auto'}
              eventDrop={(arg) => {
                onDropEvent(arg, updateEvent);
              }}
              eventResize={(arg) => {
                onResizeEvent(arg, updateEvent);
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
                luxonPlugin,
              ]}
            />
          </StyledCalendar>
        </Card>
      </Container>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openForm}
        onClose={onCloseForm}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>
          {openForm && <> {currentEvent?.id ? t('Edit class') : t('Add class')}</>}
        </DialogTitle>
        <Stack spacing={3} sx={{ px: 3, pb: 3 }}>
          <TextField
            label={t('Class type')}
            value={formType}
            onChange={(e) => setFormType(e.target.value)}
            select
          >
            {formTypes.map((opt) => (
              <MenuItem value={opt} key={opt}>
                {t(opt)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        {formType === 'Class' && (
          <CalendarClazzForm
            currentEvent={currentEvent}
            onClose={() => {
              dispatch(
                getClasses({
                  from: startDate,
                  to: endDate,
                  pageSize: 2 ** 31 - 1,
                  pageNumber: 0,
                })
              );
              onCloseForm();
            }}
          />
        )}

        {formType === 'Personal training' && (
          <CalendarPersonalTrainingForm
            currentEvent={currentEvent}
            onClose={() => {
              dispatch(
                getClasses({
                  from: startDate,
                  to: endDate,
                  pageSize: 2 ** 31 - 1,
                  pageNumber: 0,
                })
              );
              onCloseForm();
            }}
          />
        )}
      </Dialog>
    </>
  );
}
