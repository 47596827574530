import { DateTime } from 'luxon';

// ----------------------------------------------------------------------

export type IReportFilterValue = string | string[] | number[] | DateTime | null;

export type IReportTableFilters = {
  fromDate: DateTime | null;
  toDate: DateTime | null;
};

// ----------------------------------------------------------------------

export type IReportFilters = {
  name: string;
  type: string[];
  startDate: DateTime | null;
  endDate: DateTime | null;
};

// ----------------------------------------------------------------------

export type IReportManager = {
  id: string;
  name: string;
  type: string;
  url: string;
};

export type IReport = IReportManager;

// ----------------------------------------------------------------------

export const FILE_TYPE_OPTIONS = [
  'folder',
  'txt',
  'zip',
  'audio',
  'image',
  'video',
  'word',
  'excel',
  'powerpoint',
  'pdf',
  'photoshop',
  'illustrator',
];

// ----------------------------------------------------------------------

export const _members = [
  {
    id: `1_member`,
    name: 'Member churn report',
    url: 'member-churn-report',
    type: 'member',
  },
  {
    id: `2_member`,
    name: 'Member change report',
    url: 'member-change-report',
    type: 'member',
  },
  {
    id: `3_member`,
    name: 'Member retention report',
    url: 'member-retention-report',
    type: 'member',
  },
  {
    id: `4_member`,
    name: 'Average revenue per member report',
    url: 'member-average-revenue-report',
    type: 'member',
  },
  {
    id: `5_member`,
    name: 'Length of engagement report',
    url: 'member-length-of-engagement-report',
    type: 'member',
  },
  {
    id: `6_member`,
    name: 'Active members report',
    url: 'active-member-report',
    type: 'member',
  },
];

export const _memberships = [
  /* {
          id: `1_membership`,
          name: 'Memberships by Type',
          url: 'member-change-report',
          type: 'membership',
        },
        {
          id: `2_membership`,
          name: 'Expiring Memberships',
          url: 'member-change-report',
          type: 'membership',
        },
        {
          id: `3_membership`,
          name: 'Lost Memberships',
          url: 'member-change-report',
          type: 'membership',
        },
        {
          id: `4_membership`,
          name: 'Memberships with Discounts',
          url: 'member-change-report',
          type: 'membership',
        },
        {
          id: `5_membership`,
          name: 'New Memberships',
          url: 'member-change-report',
          type: 'membership',
        },
        {
          id: `6_membership`,
          name: 'On-Hold Memberships',
          url: 'member-change-report',
          type: 'membership',
        }, */
  {
    id: `7_membership`,
    name: 'Active memberships',
    url: 'active-membership-report',
    type: 'membership',
  },
];

export const _allReports = [..._members, ..._memberships];
