import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import ContractListPage from '../../pages/dashboard/contract/list';
import ContractDetailsPage from '../../pages/dashboard/contract/details';
import ContractEditPage from '../../pages/dashboard/contract/edit';
import ContractNewPage from '../../pages/dashboard/contract/new';
import EventListPage from '../../pages/dashboard/event/list';
import EventDetailsPage from '../../pages/dashboard/event/details';
import EventCreatePage from '../../pages/dashboard/event/new';
import EventEditPage from '../../pages/dashboard/event/edit';
import GymListPage from '../../pages/dashboard/gyms/list';
import GymDetailsPage from '../../pages/dashboard/gyms/details';
import MemberProfilePage from '../../pages/dashboard/member/profile';
import MemberCardsPage from '../../pages/dashboard/member/cards';
import MemberListPage from '../../pages/dashboard/member/list';
import MemberCreatePage from '../../pages/dashboard/member/new';
import MemberEditPage from '../../pages/dashboard/member/edit';
import EmployeeListPage from '../../pages/dashboard/employee/list';
import EmployeeDetailsPage from '../../pages/dashboard/employee/details';
import EmployeeEditPage from '../../pages/dashboard/employee/edit';
import EmployeeCreatePage from '../../pages/dashboard/employee/new';
import CampaignTypeListPage from '../../pages/dashboard/membership-types/campaign/list';
import CampaignTypeDetailsPage from '../../pages/dashboard/membership-types/campaign/details';
import CampaignTypeCreatePage from '../../pages/dashboard/membership-types/campaign/new';
import CampaignTypeEditPage from '../../pages/dashboard/membership-types/campaign/edit';
import ContingentTypeListPage from '../../pages/dashboard/membership-types/contingent/list';
import ContingentTypeDetailsPage from '../../pages/dashboard/membership-types/contingent/details';
import ContingentTypeCreatePage from '../../pages/dashboard/membership-types/contingent/new';
import ContingentTypeEditPage from '../../pages/dashboard/membership-types/contingent/edit';
import PersonalTrainingMembershipTypeListPage from '../../pages/dashboard/membership-types/personal-training/list';
import PersonalTrainingMembershipTypeDetailsPage from '../../pages/dashboard/membership-types/personal-training/details';
import PersonalTrainingMembershipTypeCreatePage from '../../pages/dashboard/membership-types/personal-training/new';
import PersonalTrainingMembershipTypeEditPage from '../../pages/dashboard/membership-types/personal-training/edit';
import PunchCardTypeListPage from '../../pages/dashboard/membership-types/punch-card/list';
import PunchCardTypeDetailsPage from '../../pages/dashboard/membership-types/punch-card/details';
import PunchCardTypeCreatePage from '../../pages/dashboard/membership-types/punch-card/new';
import PunchCardTypeEditPage from '../../pages/dashboard/membership-types/punch-card/edit';
import ProductCategoryListPage from '../../pages/dashboard/product-categories/list';
import ProductCategoryCreatePage from '../../pages/dashboard/product-categories/new';
import ProductCategoryEditPage from '../../pages/dashboard/product-categories/edit';
import VoucherListPage from '../../pages/dashboard/vouchers/list';
import VoucherCreatePage from '../../pages/dashboard/vouchers/new';
import VoucherEditPage from '../../pages/dashboard/vouchers/edit';
import GymCreatePage from '../../pages/dashboard/gyms/new';
import GymEditPage from '../../pages/dashboard/gyms/edit';
import MembershipCardsPage from '../../pages/dashboard/membership/cards';
import MembershipEditPage from '../../pages/dashboard/membership/edit';
import MembershipListPage from '../../pages/dashboard/membership/list';
import MembershipCreatePage from '../../pages/dashboard/membership/new';
import ClassesListPage from '../../pages/dashboard/classes/list';
import ClassesEditPage from '../../pages/dashboard/classes/edit';
import ClassesCreatePage from '../../pages/dashboard/classes/new';
import ClassesDetailsPage from '../../pages/dashboard/classes/details';
import ClassTemplateListPage from '../../pages/dashboard/class-template/list';
import ClassTemplateCreatePage from '../../pages/dashboard/class-template/new';
import ClassTemplateDetailsPage from '../../pages/dashboard/class-template/details';
import ClassTemplateEditPage from '../../pages/dashboard/class-template/edit';
import ClassDescriptionListPage from '../../pages/dashboard/class-description/list';
import ClassDescriptionCreatePage from '../../pages/dashboard/class-description/new';
import ClassDescriptionDetailsPage from '../../pages/dashboard/class-description/details';
import ClassDescriptionEditPage from '../../pages/dashboard/class-description/edit';
import FeedbackListPage from '../../pages/dashboard/feedback/list';
import FeedbackDetailsPage from '../../pages/dashboard/feedback/details';
import FeedbackCreatePage from '../../pages/dashboard/feedback/new';
import FeedbackEditPage from '../../pages/dashboard/feedback/edit';
import LeadListPage from '../../pages/dashboard/lead/list';
import RetentionListPage from '../../pages/dashboard/retention/list';
import NewsListPage from '../../pages/dashboard/news/list';
import NewsCreatePage from '../../pages/dashboard/news/new';
import NewsEditPage from '../../pages/dashboard/news/edit';
import NewsDetailsPage from '../../pages/dashboard/news/details';
import NewsletterListPage from '../../pages/dashboard/newsletter/list';
import NewsletterDetailsPage from '../../pages/dashboard/newsletter/details';
import NewsletterEditPage from '../../pages/dashboard/newsletter/edit';
import NewsletterCreatePage from '../../pages/dashboard/newsletter/new';
import NotificationListPage from '../../pages/dashboard/notification/list';
import NotificationDetailsPage from '../../pages/dashboard/notification/details';
import NotificationEditPage from '../../pages/dashboard/notification/edit';
import NotificationCreatePage from '../../pages/dashboard/notification/new';
import EmailTemplateListPage from '../../pages/dashboard/email-template/list';
import EmailTemplateDetailsPage from '../../pages/dashboard/email-template/details';
import EmailTemplateEditPage from '../../pages/dashboard/email-template/edit';
import EmailTemplateCreatePage from '../../pages/dashboard/email-template/new';
import ChainEditPage from '../../pages/dashboard/chain/edit';
import BookkeepingListPage from '../../pages/dashboard/bookkeeping/list';
import ProgramTemplateEditPage from '../../pages/dashboard/program-template/edit';
import ProgramTemplateNewPage from '../../pages/dashboard/program-template/new';
import ProgramTemplateListPage from '../../pages/dashboard/program-template/list';
import ProgramListPage from '../../pages/dashboard/program/list';
import PersonalProgramEditPage from '../../pages/dashboard/personal-program-template/edit';
import PersonalProgramListPage from '../../pages/dashboard/personal-program-template/list';
import PersonalProgramNewPage from '../../pages/dashboard/personal-program-template/new';
import ClassRegistrationListPage from '../../pages/dashboard/class-registration/list';
import SalarySettlementListPage from '../../pages/dashboard/salary-settlement/list';
import HourBankListPage from '../../pages/dashboard/hour-bank/list';
import ShiftListPage from '../../pages/dashboard/shift/list';
import WageRateListPage from '../../pages/dashboard/wage-rate/list';
import WageRateCreatePage from '../../pages/dashboard/wage-rate/new';
import WageRateDetailsPage from '../../pages/dashboard/wage-rate/details';
import WageRateEditPage from '../../pages/dashboard/wage-rate/edit';
import FaqsPage from '../../pages/faqs';
import ProgramEditPage from '../../pages/dashboard/program/edit';
import ProgramNewPage from '../../pages/dashboard/program/new';
import GuestCreatePage from '../../pages/dashboard/guest/new';
import { LocationListView } from '../../sections/location/view';
import LocationListPage from '../../pages/dashboard/location/list';
import LocationDetailsPage from '../../pages/dashboard/location/details';
import LocationCreatePage from '../../pages/dashboard/location/new';
import LocationEditPage from '../../pages/dashboard/location/edit';
import DropInTypeDetailsPage from '../../pages/dashboard/membership-types/drop-in/details';
import DropInTypeCreatePage from '../../pages/dashboard/membership-types/drop-in/new';
import DropInTypeEditPage from '../../pages/dashboard/membership-types/drop-in/edit';
import DropInTypeListPage from '../../pages/dashboard/membership-types/drop-in/list';
import ReportListPage from '../../pages/dashboard/report/list';
import ReportDetailsPage from '../../pages/dashboard/report/details';
import GymSetupPage from '../../pages/dashboard/gyms/setup';
import LockEditPage from '../../pages/dashboard/locks/edit';
import QuickLinkListPage from '../../pages/dashboard/quick-link/list';
import QuickLinkEditPage from '../../pages/dashboard/quick-link/edit';
import QuickLinkNewPage from '../../pages/dashboard/quick-link/new';
import MembershipInsightsPage from '../../pages/dashboard/insight/membership';
import SaleInsightsPage from '../../pages/dashboard/insight/sale';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/management'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/sales'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/economics'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/training'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat/list'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'sales', element: <OverviewEcommercePage /> },
      { path: 'economics', element: <OverviewBankingPage /> },
      { path: 'management', element: <IndexPage /> },
      { path: 'training', element: <OverviewBookingPage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },
      {
        path: 'members',
        children: [
          { element: <MemberProfilePage />, index: true },
          { path: ':id', element: <MemberProfilePage /> },
          { path: 'cards', element: <MemberCardsPage /> },
          { path: 'list', element: <MemberListPage /> },
          { path: 'new', element: <MemberCreatePage /> },
          { path: ':id/edit', element: <MemberEditPage /> },
        ],
      },
      {
        path: 'guests',
        children: [{ path: 'new', element: <GuestCreatePage /> }],
      },
      {
        path: 'reports',
        children: [
          { element: <ReportListPage />, index: true },
          { path: 'list', element: <ReportListPage /> },
          { path: ':slug', element: <ReportDetailsPage /> },
        ],
      },
      {
        path: 'insights',
        children: [
          { path: 'memberships', element: <MembershipInsightsPage /> },
          { path: 'sales', element: <SaleInsightsPage /> },
        ],
      },
      {
        path: 'leads',
        children: [
          { element: <LeadListPage />, index: true },
          { path: 'list', element: <LeadListPage /> },
        ],
      },
      {
        path: 'retention',
        children: [
          { element: <RetentionListPage />, index: true },
          { path: 'list', element: <RetentionListPage /> },
        ],
      },
      {
        path: 'memberships',
        children: [
          { element: <MembershipListPage />, index: true },
          { path: 'cards', element: <MembershipCardsPage /> },
          { path: 'list', element: <MembershipListPage /> },
          { path: 'new', element: <MembershipCreatePage /> },
          { path: ':id/edit', element: <MembershipEditPage /> },
        ],
      },
      {
        path: 'classes',
        children: [
          { element: <ClassesListPage />, index: true },
          { path: 'list', element: <ClassesListPage /> },
          { path: 'new', element: <ClassesCreatePage /> },
          { path: ':id', element: <ClassesDetailsPage /> },
          { path: ':id/edit', element: <ClassesEditPage /> },
        ],
      },
      {
        path: 'class-templates',
        children: [
          { element: <ClassTemplateListPage />, index: true },
          { path: 'list', element: <ClassTemplateListPage /> },
          { path: 'new', element: <ClassTemplateCreatePage /> },
          { path: ':id', element: <ClassTemplateDetailsPage /> },
          { path: ':id/edit', element: <ClassTemplateEditPage /> },
        ],
      },
      {
        path: 'class-descriptions',
        children: [
          { element: <ClassDescriptionListPage />, index: true },
          { path: 'list', element: <ClassDescriptionListPage /> },
          { path: 'new', element: <ClassDescriptionCreatePage /> },
          { path: ':id', element: <ClassDescriptionDetailsPage /> },
          { path: ':id/edit', element: <ClassDescriptionEditPage /> },
        ],
      },
      {
        path: 'wage-rates',
        children: [
          { element: <WageRateListPage />, index: true },
          { path: 'list', element: <WageRateListPage /> },
          { path: 'new', element: <WageRateCreatePage /> },
          { path: ':id', element: <WageRateDetailsPage /> },
          { path: ':id/edit', element: <WageRateEditPage /> },
        ],
      },
      {
        path: 'products',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'quick-links',
        children: [
          { element: <QuickLinkListPage />, index: true },
          { path: 'list', element: <QuickLinkListPage /> },
          { path: 'new', element: <QuickLinkNewPage /> },
          { path: ':id/edit', element: <QuickLinkEditPage /> },
        ],
      },
      {
        path: 'product-categories',
        children: [
          { element: <ProductCategoryListPage />, index: true },
          { path: 'list', element: <ProductCategoryListPage /> },
          { path: 'new', element: <ProductCategoryCreatePage /> },
          { path: ':id/edit', element: <ProductCategoryEditPage /> },
        ],
      },
      {
        path: 'vouchers',
        children: [
          { element: <VoucherListPage />, index: true },
          { path: 'list', element: <VoucherListPage /> },
          { path: 'new', element: <VoucherCreatePage /> },
          { path: ':id/edit', element: <VoucherEditPage /> },
        ],
      },
      {
        path: 'campaign-types',
        children: [
          { element: <CampaignTypeListPage />, index: true },
          { path: 'list', element: <CampaignTypeListPage /> },
          { path: ':id', element: <CampaignTypeDetailsPage /> },
          { path: 'new', element: <CampaignTypeCreatePage /> },
          { path: ':id/edit', element: <CampaignTypeEditPage /> },
        ],
      },
      {
        path: 'contingent-types',
        children: [
          { element: <ContingentTypeListPage />, index: true },
          { path: 'list', element: <ContingentTypeListPage /> },
          { path: ':id', element: <ContingentTypeDetailsPage /> },
          { path: 'new', element: <ContingentTypeCreatePage /> },
          { path: ':id/edit', element: <ContingentTypeEditPage /> },
        ],
      },
      {
        path: 'personal-training-membership-types',
        children: [
          { element: <PersonalTrainingMembershipTypeListPage />, index: true },
          { path: 'list', element: <PersonalTrainingMembershipTypeListPage /> },
          { path: ':id', element: <PersonalTrainingMembershipTypeDetailsPage /> },
          { path: 'new', element: <PersonalTrainingMembershipTypeCreatePage /> },
          { path: ':id/edit', element: <PersonalTrainingMembershipTypeEditPage /> },
        ],
      },
      {
        path: 'punch-card-types',
        children: [
          { element: <PunchCardTypeListPage />, index: true },
          { path: 'list', element: <PunchCardTypeListPage /> },
          { path: ':id', element: <PunchCardTypeDetailsPage /> },
          { path: 'new', element: <PunchCardTypeCreatePage /> },
          { path: ':id/edit', element: <PunchCardTypeEditPage /> },
        ],
      },
      {
        path: 'drop-in-types',
        children: [
          { element: <DropInTypeListPage />, index: true },
          { path: 'list', element: <DropInTypeListPage /> },
          { path: ':id', element: <DropInTypeDetailsPage /> },
          { path: 'new', element: <DropInTypeCreatePage /> },
          { path: ':id/edit', element: <DropInTypeEditPage /> },
        ],
      },
      {
        path: 'orders',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'contracts',
        children: [
          { element: <ContractListPage />, index: true },
          { path: 'list', element: <ContractListPage /> },
          { path: ':id', element: <ContractDetailsPage /> },
          { path: ':id/edit', element: <ContractEditPage /> },
          { path: 'new', element: <ContractNewPage /> },
        ],
      },
      {
        path: 'program-templates',
        children: [
          { element: <ProgramTemplateListPage />, index: true },
          { path: 'list', element: <ProgramTemplateListPage /> },
          { path: ':id/edit', element: <ProgramTemplateEditPage /> },
          { path: 'new', element: <ProgramTemplateNewPage /> },
        ],
      },
      {
        path: 'personal-programs',
        children: [
          { element: <PersonalProgramListPage />, index: true },
          { path: 'list', element: <PersonalProgramListPage /> },
          { path: ':id/edit', element: <PersonalProgramEditPage /> },
          { path: 'new', element: <PersonalProgramNewPage /> },
        ],
      },
      {
        path: 'programs',
        children: [
          { element: <ProgramListPage />, index: true },
          { path: 'list', element: <ProgramListPage /> },
          { path: ':id/edit', element: <ProgramEditPage /> },
          { path: 'new', element: <ProgramNewPage /> },
        ],
      },
      {
        path: 'invoices',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'employees',
        children: [
          { element: <EmployeeListPage />, index: true },
          { path: 'list', element: <EmployeeListPage /> },
          { path: ':id', element: <EmployeeDetailsPage /> },
          { path: ':id/edit', element: <EmployeeEditPage /> },
          { path: 'new', element: <EmployeeCreatePage /> },
        ],
      },
      {
        path: 'news',
        children: [
          { element: <NewsListPage />, index: true },
          { path: 'list', element: <NewsListPage /> },
          { path: ':id', element: <NewsDetailsPage /> },
          { path: ':id/edit', element: <NewsEditPage /> },
          { path: 'new', element: <NewsCreatePage /> },
        ],
      },
      {
        path: 'newsletters',
        children: [
          { element: <NewsletterListPage />, index: true },
          { path: 'list', element: <NewsletterListPage /> },
          { path: ':id', element: <NewsletterDetailsPage /> },
          { path: ':id/edit', element: <NewsletterEditPage /> },
          { path: 'new', element: <NewsletterCreatePage /> },
        ],
      },
      {
        path: 'notifications',
        children: [
          { element: <NotificationListPage />, index: true },
          { path: 'list', element: <NotificationListPage /> },
          { path: ':id', element: <NotificationDetailsPage /> },
          { path: ':id/edit', element: <NotificationEditPage /> },
          { path: 'new', element: <NotificationCreatePage /> },
        ],
      },
      {
        path: 'email-templates',
        children: [
          { element: <EmailTemplateListPage />, index: true },
          { path: 'list', element: <EmailTemplateListPage /> },
          { path: ':id', element: <EmailTemplateDetailsPage /> },
          { path: ':id/edit', element: <EmailTemplateEditPage /> },
          { path: 'new', element: <EmailTemplateCreatePage /> },
        ],
      },
      {
        path: 'bookkeeping',
        children: [
          { element: <BookkeepingListPage />, index: true },
          { path: 'list', element: <BookkeepingListPage /> },
        ],
      },
      {
        path: 'salary-settlement',
        children: [
          { element: <SalarySettlementListPage />, index: true },
          { path: 'list', element: <BookkeepingListPage /> },
        ],
      },
      {
        path: 'shifts',
        children: [
          { element: <ShiftListPage />, index: true },
          { path: 'list', element: <ShiftListPage /> },
        ],
      },
      {
        path: 'hour-bank',
        children: [
          { element: <HourBankListPage />, index: true },
          { path: 'list', element: <BookkeepingListPage /> },
        ],
      },
      {
        path: 'events',
        children: [
          { element: <EventListPage />, index: true },
          { path: 'list', element: <EventListPage /> },
          { path: ':id', element: <EventDetailsPage /> },
          { path: 'new', element: <EventCreatePage /> },
          { path: ':id/edit', element: <EventEditPage /> },
        ],
      },
      {
        path: 'class-registrations',
        children: [{ element: <ClassRegistrationListPage />, index: true }],
      },
      {
        path: 'feedback',
        children: [
          { element: <FeedbackListPage />, index: true },
          { path: 'list', element: <FeedbackListPage /> },
          { path: ':id', element: <FeedbackDetailsPage /> },
          { path: 'new', element: <FeedbackCreatePage /> },
          { path: ':id/edit', element: <FeedbackEditPage /> },
        ],
      },
      {
        path: 'chains',
        children: [{ path: ':id/edit', element: <ChainEditPage /> }],
      },
      {
        path: 'gyms',
        children: [
          { element: <GymListPage />, index: true },
          { path: 'list', element: <GymListPage /> },
          { path: ':id', element: <GymDetailsPage /> },
          { path: 'new', element: <GymCreatePage /> },
          { path: ':id/edit', element: <GymEditPage /> },
          { path: ':id/setup', element: <GymSetupPage /> },
        ],
      },
      {
        path: 'locks',
        children: [{ path: ':id/edit', element: <LockEditPage /> }],
      },
      {
        path: 'locations',
        children: [
          { element: <LocationListPage />, index: true },
          { path: 'list', element: <LocationListPage /> },
          { path: ':id', element: <LocationDetailsPage /> },
          { path: 'new', element: <LocationCreatePage /> },
          { path: ':id/edit', element: <LocationEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      {
        path: 'chat',
        children: [
          { element: <ChatPage />, index: true },
          { path: ':id', element: <ChatPage /> },
        ],
      },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
      { path: 'faqs', element: <FaqsPage /> },
    ],
  },
];
