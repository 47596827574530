import { useEffect } from 'react';
import { useAuthContext } from '../../auth/hooks';
import { useSelector } from '../../redux/store';

export default function SupportChat() {
  const { user } = useAuthContext();
  const chain = useSelector((state) => state.chain.currentChain);

  useEffect(() => {
    const w = window as any;

    w.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'hdbfq0dg',
      user_id: user?.id,
      name: user?.fullName,
      email: user?.email,
      created_at: user?.createdDate,
      avatar: user?.profileImageUrl,
      company: {
        id: chain?.id,
        name: chain?.name,
        plan: chain?.subscriptionType,
      },
    });
  }, [user, chain]);

  return <></>;
}
