import * as Yup from 'yup';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';
// types
import { setError } from 'src/redux/slices/error';
import { useDispatch } from 'src/redux/store';
import { ContractDto, ContractsService } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentContract?: ContractDto;
};

export default function ContractNewEditForm({ currentContract }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    body: Yup.string().required(t('Body is required')),
    requireNewSignature: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentContract?.name || '',
      body: currentContract?.body || '',
      requireNewSignature: false,
    }),
    [currentContract]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentContract) {
      reset(defaultValues);
    }
  }, [currentContract, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentContract) {
        await ContractsService.putContractTemplate({
          id: currentContract!.id!,
          body: { id: currentContract!.id!, ...data },
        });
      } else {
        await ContractsService.postContractTemplate({ body: { ...data } });
      }

      reset();
      enqueueSnackbar(currentContract ? t('Update success!') : t('Create success!'));
      router.push(paths.contracts.root);
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here you fill out what your contract includes. Like fees, membership cancellation etc..'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('Name')} </Typography>
              <RHFTextField name="name" placeholder="Ex: Adventure Seekers Expedition..." />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2"> {t('Content')} </Typography>
              <RHFEditor simple name="body" />
            </Stack>

            <RHFSwitch name="requireNewSignature" label={t('Require new signature?')} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentContract ? `${t('Create')}` : `${t('Save Changes')}`}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
