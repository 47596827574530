import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import MemberRetentionReportToolbar from './member-retention-report-toolbar';

import { IReportFilterValue, IReportTableFilters } from '../../../types/report';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import { getMemberChurnReport } from '../../../redux/slices/report';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber } from '../../../utils/format-number';
import HeatmapWidget from '../../overview/management/heatmap-widget';
import {
  getMembershipRetentionStats,
  getMembersRetentionStats,
} from '../../../redux/slices/members';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().minus({ months: 11 }).startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

// ----------------------------------------------------------------------

export default function MemberRetentionReportView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const [filters, setFilters] = useState(defaultFilters);

  const membersRetentionStats = useSelector((state) => state.member.membersRetentionStats);

  useEffect(() => {
    dispatch(getMembersRetentionStats());
  }, [dispatch, filters]);

  const handleFilters = useCallback((name: string, value: IReportFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);
  const handlePrintPage: VoidFunction = () => window.print();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Member retention report')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Reports')}`,
            href: paths.report.root,
          },
          { name: `${t('Member retention report')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} id="printarea">
        <Card>
          <MemberRetentionReportToolbar
            filters={filters}
            onFilters={handleFilters}
            onPrint={handlePrintPage}
          />
        </Card>
        <HeatmapWidget
          title={t('Member retention')}
          subheader={t(
            'Shows the retention rate for any given member that has signed up in the given period.'
          )}
          chart={{
            series:
              membersRetentionStats?.datasets?.map((e) => ({
                name: t(e.label!),
                data: e.data!,
              })) ?? [],
            labels: membersRetentionStats.labels ?? [],
          }}
        />
      </Stack>
    </Container>
  );
}
